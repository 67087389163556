import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import SidebarHeader from "../components/SidebarHeader";
import Login from '../screens/Login/login';
import BankVoucher from "../screens/bankVoucher/bankVoucher"
import DeletedCashVoucher from ".././screens/cashVoucher/deletedVoucher"
import DeletedBankVoucher from ".././screens/bankVoucher/deletedVoucher"
import DeletedRecieptVoucher from ".././screens/receiptVoucher/deletedVoucher"
import CashVoucher from "../screens/cashVoucher/cashVoucher"
import ReceiptVoucher from "../screens/receiptVoucher/receiptVoucher"
// import RefundVoucher from "../screens/refundVoucher/RefundVoucher"
import Hall from "../../src/screens/Hall/hall"
import Party from "../../src/screens/Party/party"
import User from "../../src/screens/Users/User"
import Expenditure from "../../src/screens/Expenditure/expenditure"
import ErrorPage from '../components/ErrorPage'





function AppRoutes() {
    const isLoggedIn = sessionStorage.getItem('isAuth') === "true" ? true : false;
    const role = sessionStorage.getItem("role");
    if (isLoggedIn) {

        return (
            <Router>
                <SidebarHeader />
                <Routes>
                    <Route path={`/`} element={<Navigate replace to={`/cashvoucher`} />} />
                    <Route path={`/cashvoucher`} element={<CashVoucher />} />
                    <Route path={`/trackcashvoucher`} element={<DeletedCashVoucher />} />
                    <Route path={`/bankvoucher`} element={<BankVoucher />} />
                    <Route path={`/trackbankvoucher`} element={<DeletedBankVoucher />} />
                    <Route path={`/receiptvoucher`} element={<ReceiptVoucher />} />
                    <Route path={`/trackreceiptvoucher`} element={<DeletedRecieptVoucher />} />
                    {/* <Route path={`/refundvoucher`} element={<RefundVoucher/>} /> */}
                    <Route path={`/hall`} element={<Hall />} />
                    <Route path={`/party`} element={<Party />} />
                    {(role === "Admin") &&
                        <Route path={`/users`} element={<User />} />}
                    <Route path={`/expenditure`} element={<Expenditure />} />
                    <Route path={`/*`} element={<ErrorPage />} />
                </Routes>
            </Router>
        );
    } else {
        return (
            <Router>
                <Routes>
                    <Route path={`/`} element={<Login />} exact />
                    <Route path={'*'} element={<Navigate replace to={`/`} />} />
                </Routes>
            </Router>
        )
    }


}

export default AppRoutes;