import React, { useState, useEffect, useRef } from "react";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import moment from "moment";
import "./style.scss";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import deletebtn from "../../assets/images/trash (1).png";
import editbtn from "../../assets/images/edit (3).png";
import EditModal from "../../components/Modal/BankVoucherEditModal";
import Confirmationmodal from "../../components/Modal/BankVoucherConfirmation";
import calendar from "../../assets/images/calendar.png";
import Loader from "../../helper/Loader/Loader";
import ReactExport from "react-export-excel";
import downloadPdfImage from "../../assets/images/download.png";
import DownloadPdfModal from "../../components/Modal/DownloadPdfModal";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const limit = [
  {
    option: 2,
    value: 2,
  },
  {
    option: 10,
    value: 10,
  },
  {
    option: 100,
    value: 100,
  },
  {
    option: 500,
    value: 500,
  },
  {
    option: 1000,
    value: 1000,
  },
];

function BankVoucher() {
  const startCalendarRef = useRef();
  const endCalendarRef = useRef();
  const { authStore } = useStores();
  const userId = sessionStorage.getItem("id");
  const [isLoading, setIsLoading] = useState(false);
  const [partyies, setPartyies] = useState([]);
  const [addedName, setAddedName] = useState("");
  const [show, setShow] = useState(true);
  const [selectedParty, setSelectedParty] = useState("");
  const [particular, setParticular] = useState("");
  const [amount, setAmount] = useState();
  const [approvedAmount, setApprovedAmount] = useState();
  const [rejectedAmount, setRejectedAmount] = useState();
  const [tdsDeducted, setTdsDeducted] = useState();
  const [rejectionReason, setRejectionReason] = useState("");
  const [mode, setMode] = useState("");
  const [chequeNumber, setChequeNumber] = useState();
  const [neftNumber, setNeftNumber] = useState();
  const createdd = sessionStorage.getItem("id");
  const [bankData, setBankData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalPdf, setShowModalPdf] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState();
  const [voucherNum, setVoucherNum] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [filterVoucherNumber, setFilterVoucherNumber] = useState("");
  const [filterparty, setFilterParty] = useState("");
  const [filteramount, setFilterAmount] = useState("");
  const [filterPaymentMode, setFilterPaymentMode] = useState("");
  const [filterChequeNumber, setFilterChequeNumber] = useState("");
  const [filterNeftNumber, setFilterNeftNumber] = useState("");
  const [pageLimit, setPageLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [bankVoucherData, setBankVoucherData] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const role = sessionStorage.getItem("role");
  const date = sessionStorage.getItem("date");
  let currentDate = sessionStorage.getItem("date");
  // currentDate = moment(new Date(currentDate)).format("DD-MM-YYYY");
  if (currentDate) {
    currentDate = moment(currentDate, "DD-MM-YYYY").format("YYYY-MM-DD");
  }
  const [voucherDate, setVoucherDate] = useState(currentDate);
  const [showExpenditure, setShowExpenditure] = useState(true);
  const [selectExpenditure, setSelectExpenditure] = useState("")
  const [expenditure, setExpenditure] = useState([]);
  const [addedExpenditure, setAddedExpenditure] = useState("")
  const [bankName , setBankName] = useState("")
  useEffect(() => {
    setIsAdmin(role === "Admin");

    handlepartydropdown();
    handleGetBankVoucherDetail();
    handleExpenditure()
  }, []);

  const handleGetBankVoucherDetail = async () => {
    setIsLoading(true);
    let payload = {
      limit: pageLimit,
      filters: {},
      pageNumber: page,
    };
    callApi(payload);
  };

  const handlepartydropdown = async () => {
    setIsLoading(true);
    await authStore.partiesList(
      (response) => {
        if (response?.meta?.status === "success") {
          let data = [...response?.data?.parties];
          let obj = {
            id: "0",
            name: "Other",
          };
          data.push(obj);
          const selectOptions = data.map((option) => ({
            value: option.id,
            label: option.name,
          }));
          setPartyies(selectOptions);
          setIsLoading(false);
        } else {
          notify(response?.meta?.message, "danger");
          setIsLoading(false);
        }
      },
      (error) => {
        notify(error?.response?.meta?.message, "danger");
        setIsLoading(false);
      }
    );
  };

  const handlePartyChangeName = (event) => {
    const selectedValue = event;
    setSelectedParty(selectedValue);
  };
  const handleExpenditureChange = (event) => {
    const selectedValue = event;
    setSelectExpenditure(selectedValue);
  };

  const handleMenuOpen1 = () => {
    setSelectExpenditure("");
  };
  const handleAddCustomOption = async () => {
    setIsLoading(true);
    setShow(false);
    let payload = {
      name: addedName,
    };
    await authStore.addParty(
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          notify(response?.data?.meta?.message, "success");
          setIsLoading(false);
          handlepartydropdown();
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false);
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false);
      }
    );
  };

  const handleCreateBankVoucher = async () => {
    setIsLoading(true);
    let payload = {
      partyId: selectedParty?.value,
      particulars: particular,
      amount: amount,
      createdBy: createdd,
      approvedAmount: approvedAmount,
      paymentMode: mode,
      chequeNumber: chequeNumber,
      expenditureHead: selectExpenditure?.value,
      bankName : bankName
    };

    if (
      tdsDeducted !== null &&
      tdsDeducted !== undefined &&
      tdsDeducted !== ""
    ) {
      payload.deductedTds = tdsDeducted;
    }
    if (
      rejectedAmount !== null &&
      rejectedAmount !== undefined &&
      rejectedAmount !== ""
    ) {
      payload.rejectedAmount = rejectedAmount;
    }

    if (
      rejectionReason !== null &&
      rejectionReason !== undefined &&
      rejectionReason !== ""
    ) {
      payload.rejectedReason = rejectionReason;
    }
    if (neftNumber !== null && neftNumber !== undefined && neftNumber !== "") {
      payload.neftNumber = neftNumber;
    }

    if (role === "Admin") {
      payload.voucherDate = voucherDate;
    }
    await authStore.createBankVoucher(
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          notify(response?.data?.meta?.message, "success");
          setIsLoading(false);
          handleGetBankVoucherDetail();
          setSelectExpenditure("")
          setSelectedParty("")
          setParticular("")
          setAmount("")
          setApprovedAmount("")
          setRejectedAmount("")
          setTdsDeducted("")
          setRejectionReason("")
          setMode("")
          setChequeNumber("")
          setBankName("")
          setNeftNumber("")
        } else {
          notify(response?.data?.meta?.message, "danger");
          setSelectExpenditure("")
          setSelectedParty("")
          setParticular("")
          setAmount("")
          setApprovedAmount("")
          setRejectedAmount("")
          setTdsDeducted("")
          setRejectionReason("")
          setMode("")
          setChequeNumber("")
          setBankName("")
          setNeftNumber("")
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false);
        setSelectExpenditure("")
        setSelectedParty("")
        setParticular("")
        setAmount("")
        setApprovedAmount("")
        setRejectedAmount("")
        setTdsDeducted("")
        setRejectionReason("")
        setMode("")
        setChequeNumber("")
        setBankName("")
        setNeftNumber("")
      }
    );
  };

  const handleEdit = (e, item) => {
    e.preventDefault();
    setSelectedVoucher(item);
    setShowModal(true);
  };

  const handleDeleteEntry = async (item) => {
    setShowConfirmationModal(true);
    setVoucherNum(item?.voucher_number);
  };

  const handleConfirmation = async () => {
    let payload = {
      userId: userId,
    };
    await authStore.deleteBankVoucher(
      voucherNum,
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          notify(response?.data?.meta?.message);
          setShowConfirmationModal(false);
          handleGetBankVoucherDetail();
        } else {
          notify(response?.data?.meta?.message, "danger");
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
      }
    );
  };

  const handleStartDate = (date) => {
    setStartDate(date);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
  };

  const openDatepicker1 = () => {
    startCalendarRef.current.setFocus(true);
  };

  const openDatepicker2 = () => {
    endCalendarRef.current.setFocus(true);
  };

  const handleFilter = async () => {
    setIsLoading(true);
    let newStartDate = startDate
      ? moment(new Date(startDate)).format("YYYY-MM-DD")
      : null;
    let newEndDate = endDate
      ? moment(new Date(endDate)).format("YYYY-MM-DD")
      : null;
    let payload = {
      limit: pageLimit,
      filters: {
        voucherNumber: filterVoucherNumber,
        voucherFromDate: newStartDate,
        voucherToDate: newEndDate,
        partyName: filterparty,
        amount: filteramount,
        modeOfPayment: filterPaymentMode,
        chequeNumber: filterChequeNumber,
        neftNumber: filterNeftNumber,
      },
      pageNumber: page,
    };
    callApi(payload);
  };

  const handleReset = async () => {
    setFilterAmount("");
    setFilterParty("");
    setFilterVoucherNumber("");
    setFilterPaymentMode("");
    setFilterChequeNumber("");
    setFilterNeftNumber("");
    setPage(1);
    setPageLimit(10);
    startCalendarRef.current.value = "";
    endCalendarRef.current.value = "";
    setStartDate(null);
    setEndDate(null);
    await handleGetBankVoucherDetail();
  };

  const handlePageSubmit = async (index) => {
    setIsLoading(true);
    setPage(index.selected + 1);
    let newStartDate = startDate
      ? moment(new Date(startDate)).format("YYYY-MM-DD")
      : null;
    let newEndDate = endDate
      ? moment(new Date(endDate)).format("YYYY-MM-DD")
      : null;
    let payload = {
      limit: pageLimit,
      filters: {
        voucherNumber: filterVoucherNumber,
        voucherFromDate: newStartDate,
        voucherToDate: newEndDate,
        partyName: filterparty,
        amount: filteramount,
        modeOfPayment: filterPaymentMode,
        chequeNumber: filterChequeNumber,
        neftNumber: filterNeftNumber,
      },
      pageNumber: index.selected + 1,
    };
    callApi(payload);
  };

  const limitChangeHandler = async (index) => {
    setIsLoading(true);
    setPageLimit(index);
    let newStartDate = startDate
      ? moment(new Date(startDate)).format("YYYY-MM-DD")
      : null;
    let newEndDate = endDate
      ? moment(new Date(endDate)).format("YYYY-MM-DD")
      : null;
    let payload = {
      limit: index,
      filters: {
        voucherNumber: filterVoucherNumber,
        voucherFromDate: newStartDate,
        voucherToDate: newEndDate,
        partyName: filterparty,
        amount: filteramount,
        modeOfPayment: filterPaymentMode,
        chequeNumber: filterChequeNumber,
        neftNumber: filterNeftNumber,
      },
      pageNumber: page,
    };
    callApi(payload);
  };

  const callApi = async (payload) => {
    await authStore.getBankVoucher(
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          setBankVoucherData(response?.data?.data);
          setBankData(response?.data?.data?.filteredData?.results);
          setIsLoading(false);
        } else {
          notify(response?.data?.meta?.message);
          setIsLoading(false);
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message);
        setIsLoading(false);
      }
    );
  };

  const handledownload = (item) => {
    setShowModalPdf(true);
    let {
      voucher_number,
      voucher_date,
      parties,
      particulars,
      approved_amount,
      rejected_amount,
      deducted_tds,
      rejection_reason,
      mode_of_payment,
      cheque_number,
      neft_number,
      expenditure_heads,
      bank_name,
       users,
    } = item;
    setSelectedRow({
      "Voucher Number": voucher_number,
      Date:
        voucher_date &&
        new Date(voucher_date.split("T")[0]).toLocaleDateString("en-GB"),
      "Party Name": parties?.name,
      Particulars: particulars,
      "Approved Amount": approved_amount,
      "Rejected Amount": rejected_amount,
      "TDS Deducted": deducted_tds,
      "Rejection Reason": rejection_reason,
      Mode: mode_of_payment,
      "Cheque Number": cheque_number,
      "Neft Number": neft_number,
      "Expenditure Head": expenditure_heads?.name,
      "Bank Name" : bank_name,
      "Created By": users?.name,
      "Approver Signature": "",
      "Counter party Signature": "",
    });
  };

  const calculateDayDifference = (givenDate) => {
    const parsedGivenDate = new Date(givenDate);
    let currDate = new Date();
    const timeDifference = currDate - parsedGivenDate.getTime();
    const daysDifference = timeDifference / (1000 * 3600 * 24);
    return daysDifference <= 10;
  };

  const handleMenuOpen = () => {
    setSelectedParty("");
  };

  const handleExpenditure = async () => {
    setIsLoading(true);
    await authStore.expenditureList(
      (response) => {
        if (response?.meta?.status === "success") {
          let data = [...response?.data?.heads];
          let obj = {
            id: "0",
            name: "Other",
          };
          data.push(obj);
          const selectOptions = data.map((option) => ({
            value: option.id,
            label: option.name,
          }));
          setExpenditure(selectOptions);
          setIsLoading(false);
        } else {
          notify(response?.meta?.message, "danger");
          setIsLoading(false);
        }
      },
      (error) => {
        notify(error?.response?.meta?.message, "danger");
        setIsLoading(false);
      }
    );
  };
  const handleAddExpenditure = async () => {
    setIsLoading(true);
    setShowExpenditure(false);
    let payload = {
      name: addedExpenditure,
    };
    await authStore.addExpenditure(
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          notify(response?.data?.meta?.message, "success");
          setIsLoading(false);
          handleExpenditure();
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false);
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false);
      }
    );
  }

  return (
    <>
      <div className="leaddetails-container">
        <div className="RightArea">
          <div className="dashboard-area">
            <div className="container-fluid dash-container">
              <div className="needs-validation">
                <div className="row">
                  <div className="col-md-12">
                    <div className="hall-header">
                      <h2 className="sub-heading">Bank Voucher</h2>
                    </div>
                  </div>
                  <div className="receipt-form-box">
                    <div className="row">
                      <div className="col-md-3">
                        <label className="input-lable">Voucher Date</label>
                        <input
                          readOnly
                          type="text"
                          value={date}
                          className="form-control"
                          id="last name"
                          placeholder={date}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="input-lable" for="partyName">
                          Name of Party<span className="required-field">*</span>
                        </label>
                        <Select
                          value={selectedParty}
                          onChange={handlePartyChangeName}
                          options={partyies}
                          isSearchable={true}
                          placeholder="search for party"
                          onMenuOpen={handleMenuOpen}
                        />
                      </div>
                      {selectedParty?.label === "Other" && show && (
                        <div className="col-md-3">
                          <label className="input-lable">Add Name</label>
                          <div style={{ position: "relative" }}>
                            <input
                              type="text"
                              className="form-control"
                              id="customName"
                              placeholder="Type other name"
                              value={addedName?.toUpperCase()}
                              onChange={(e) => {
                                let data = e.target.value.toUpperCase();
                                setAddedName(data)
                              }}
                            />
                            <button
                              className="add-button"
                              onClick={handleAddCustomOption}
                            >
                              Add Name
                            </button>
                          </div>
                        </div>
                      )}
                      <div className="col-md-3">
                        <label className="input-lable">
                          Particulars<span className="required-field">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="Particulars"
                          placeholder="Particulars"
                          value={particular}
                          onChange={(e) => {
                            let data = e.target.value.toUpperCase();
                            setParticular(data)
                          }}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="input-lable">
                          Amount<span className="required-field">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="Amount"
                          placeholder="Amount"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="input-lable">
                          Approved Amount
                          <span className="required-field">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="approvedAmount"
                          placeholder="Approved Amount"
                          value={approvedAmount}
                          onChange={(e) => setApprovedAmount(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="input-lable">Rejected Amount</label>
                        <input
                          type="text"
                          className="form-control"
                          id="rejectedAmount"
                          placeholder="Rejected Amount"
                          value={rejectedAmount}
                          onChange={(e) => setRejectedAmount(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="input-lable">TDS Deducted</label>
                        <input
                          type="text"
                          className="form-control"
                          id="tdsDeducted"
                          placeholder="TDS Deducted"
                          value={tdsDeducted}
                          onChange={(e) => setTdsDeducted(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="input-lable">
                          Reason For Rejection
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="rejectionReason"
                          placeholder="Reason For Rejection"
                          value={rejectionReason}
                          onChange={(e) => {
                            let data = e.target.value.toUpperCase();
                            setRejectionReason(data)
                          }}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="input-lable">
                          Mode (NEFT/CHQ/OTHERS)
                          <span className="required-field">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="mode"
                          placeholder="Mode"
                          value={mode?.toUpperCase()}
                          onChange={(e) => {
                            let data = e.target.value.toUpperCase();
                            setMode(data)
                          }}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="input-lable">Cheque Number</label>
                        <input
                          type="text"
                          className="form-control"
                          id="chequeNumber"
                          placeholder="Cheque Number"
                          value={chequeNumber}
                          onChange={(e) => {
                            let data = e.target.value.toUpperCase();
                            setChequeNumber(data)
                          }}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="input-lable">NEFT Number</label>
                        <input
                          type="text"
                          className="form-control"
                          id="neftNumber"
                          placeholder="NEFT Number"
                          value={neftNumber}
                          onChange={(e) => {
                            let data = e.target.value.toUpperCase();
                            setNeftNumber(data)
                          }}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="input-lable">
                          Expenditure Head<span className="required-field">*</span>
                        </label>
                        <Select
                          value={selectExpenditure}
                          onChange={handleExpenditureChange}
                          options={expenditure}
                          isSearchable={true}
                          placeholder="search for expenditure"
                          onMenuOpen={handleMenuOpen1}
                        />
                      </div>

                      {selectExpenditure?.label === "Other" && showExpenditure && (
                        <div className="col-md-3">
                          <label className="input-lable">Add Expenditure</label>
                          <div style={{ position: "relative" }}>
                            <input
                              type="text"
                              className="form-control"
                              id="customName"
                              placeholder="Type other name"
                              value={addedExpenditure?.toUpperCase()}
                              onChange={(e) => {
                                let data = e.target.value.toUpperCase();
                                setAddedExpenditure(data)
                              }}
                            />
                            <button
                              className="add-button"
                              onClick={handleAddExpenditure}
                            >
                              Add Exp
                            </button>
                          </div>
                        </div>
                      )}

                      <div className="col-md-3">
                        <label className="input-lable">
                        Bank Name<span className="required-field">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Bank Name"
                          value={bankName}
                          onChange={(e) => {
                            let data = e.target.value.toUpperCase();
                            setBankName(data)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12" style={{ textAlign: "right" }}>
                  <button
                    className="btn btn-primary submit-button"
                    onClick={handleCreateBankVoucher}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="needs-validation">
                <div className="row">
                  <div className="col-md-12">
                    <div className="hall-header">
                      <h2 className="sub-heading">Entries</h2>
                    </div>
                  </div>
                  <div className="col-md-12 filter-container">
                    <div className="calendar-container">
                      <DatePicker
                        className="form-control"
                        placeholderText="From Date"
                        selected={startDate}
                        maxDate={new Date()}
                        onChange={(date) => {
                          handleStartDate(date);
                        }} //only when value has changed
                        dateFormat="dd-MM-yyyy"
                        ref={startCalendarRef}
                      />
                      <div className="calendar-icon">
                        <img
                          src={calendar}
                          alt="fromcal"
                          width="20px"
                          onClick={openDatepicker1}
                        />
                      </div>
                    </div>
                    <div className="calendar-container">
                      <DatePicker
                        className="form-control"
                        placeholderText="To Date"
                        selected={endDate}
                        maxDate={new Date()}
                        onChange={(date) => {
                          handleEndDate(date);
                        }} //only when value has changed
                        dateFormat="dd-MM-yyyy"
                        ref={endCalendarRef}
                      />
                      <div className="calendar-icon">
                        <img
                          src={calendar}
                          alt="tocal"
                          width="20px"
                          onClick={openDatepicker2}
                        />
                      </div>
                    </div>
                    <div>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Voucher Number"
                        value={filterVoucherNumber}
                        onChange={(e) => setFilterVoucherNumber(e.target.value)}
                      />
                    </div>
                    <div>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Party Name"
                        value={filterparty?.toUpperCase()}
                        onChange={(e) => setFilterParty(e.target.value)}
                      />
                    </div>
                    <div>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Amount"
                        value={filteramount}
                        onChange={(e) => setFilterAmount(e.target.value)}
                      />
                    </div>
                    <div>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Mode of Payment"
                        value={filterPaymentMode?.toUpperCase()}
                        onChange={(e) => setFilterPaymentMode(e.target.value)}
                      />
                    </div>
                    <div>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Cheque Number"
                        value={filterChequeNumber}
                        onChange={(e) => setFilterChequeNumber(e.target.value)}
                      />
                    </div>
                    <div>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="NEFT Number"
                        value={filterNeftNumber}
                        onChange={(e) => setFilterNeftNumber(e.target.value)}
                      />
                    </div>
                    <div className="btn-container">
                      <button
                        className="submit-button"
                        type="submit"
                        onClick={handleFilter}
                      >
                        Filter
                      </button>
                      <button
                        className="submit-button"
                        type="submit"
                        onClick={handleReset}
                      >
                        Reset
                      </button>
                      {bankData.length > 0 && (
                        <button className="submit-button">
                          <ExcelFile element={<span style={{ cursor: "pointer" }}  > Export  </span>} >
                            <ExcelSheet
                              data={bankData.map((item, i) => ({
                                ...item,
                                index: i + 1,
                                voucher_date: new Date(
                                  item.voucher_date
                                ).toLocaleDateString("en-GB", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "2-digit",
                                }),
                              }))}
                              name="Sheet"
                            >
                              <ExcelColumn label="Sr.No" value="index" />
                              <ExcelColumn
                                label="Voucher No."
                                value="voucher_number"
                              />
                              <ExcelColumn
                                label="Voucher Date"
                                value="voucher_date"
                              />
                              <ExcelColumn
                                label="Name of Party"
                                value={(item) => item?.parties?.name?.toUpperCase()}
                              />
                              <ExcelColumn
                                label="Particulars"
                                value={(item) => item?.particulars?.toUpperCase()}
                              />
                              <ExcelColumn label="Amount" value="amount" />
                              <ExcelColumn
                                label="Approved Amount"
                                value="approved_amount"
                              />
                              <ExcelColumn
                                label="Rejected Amount"
                                value="rejected_amount"
                              />
                              <ExcelColumn
                                label="TDS Deducted	"
                                value="deducted_tds"
                              />
                              <ExcelColumn
                                label="Reason For Rejection	"
                                value={(item) => item?.rejection_reason?.toUpperCase()}
                              />
                              <ExcelColumn
                                label="Mode"
                                value={(item) => item?.mode_of_payment?.toUpperCase()}
                              />
                              <ExcelColumn
                                label="Cheque Number	"
                                value="cheque_number"
                              />
                              <ExcelColumn
                                label="NEFT Number	"
                                value="neft_number"
                              />
                              <ExcelColumn
                                label="Expenditure Head	"
                                value={(item) => item?.expenditure_heads?.name?.toUpperCase()}
                              />
                              <ExcelColumn
                                label="Bank Name	"
                                value={(item) => item?.bank_name?.toUpperCase()}
                              />
                            </ExcelSheet>
                          </ExcelFile>
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div>
                      <label className="d-flex align-items-center p-0 col-md-2">
                        {page === 1 ? (
                          <select
                            value={pageLimit}
                            className="form-control selectbox-container mr-2"
                            onChange={(e) => limitChangeHandler(e.target.value)}
                          >
                            {limit.length > 0
                              ? limit.map((item, index) => (
                                <option key={index} value={item.option}>
                                  {item.value}
                                </option>
                              ))
                              : null}
                          </select>
                        ) : (
                          <p className="form-control selectbox-container mx-2 my-0">
                            {pageLimit}
                          </p>
                        )}
                        <span> entries</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12 listing-table">
                    <div className="data-table  py-4 table-responsive">
                      <table className="details-box table-bordered  ">
                        <thead className="thead-box">
                          <tr className="thead">
                            <th className="action">Sr.No</th>
                            <th className="action action-res">Voucher No.</th>
                            <th className="action action-res">Voucher Date</th>
                            <th className="name action-res">Name of Party</th>
                            <th className="name action-res">Particulars</th>
                            <th className="name action-res">Amount</th>
                            <th className="name action-response">
                              Approved Amount
                            </th>
                            <th className="name action-response">
                              Rejected Amount
                            </th>
                            <th className="name action-res">TDS Deducted</th>
                            <th className="name action-response">
                              Reason For Rejection
                            </th>
                            <th className="name action-res">Mode</th>
                            <th className="name action-response">
                              Cheque Number
                            </th>
                            <th className="name action-res">NEFT Number</th>
                            <th className="name action-response">Expenditure Head</th>
                            <th className="name action-res">Bank Name</th>
                            <th className="action">Action</th>
                          </tr>
                        </thead>
                        {bankData.length > 0 ? (
                          <tbody>
                            {bankData.map((item, i) => {
                              return (
                                <>
                                  <tr>
                                    <td className="table-td">{i + 1}</td>
                                    <td className="table-td">
                                      {item.voucher_number}
                                    </td>
                                    <td className="table-td">
                                      {item?.voucher_date &&
                                        new Date(
                                          item.voucher_date
                                        ).toLocaleDateString("en-GB")}
                                    </td>
                                    <td className="table-td">
                                      {item.parties?.name}
                                    </td>
                                    <td className="table-td">
                                      {item.particulars}
                                    </td>
                                    <td className="table-td">{item.amount}</td>
                                    <td className="table-td">
                                      {item.approved_amount}
                                    </td>

                                    <td className="table-td">
                                      {item?.rejected_amount?.length > 0
                                        ? item?.rejected_amount
                                        : "nil"}
                                    </td>
                                    <td className="table-td">
                                      {item.deducted_tds?.length > 0
                                        ? item.deducted_tds
                                        : "nil"}
                                    </td>
                                    <td className="table-td">
                                      {item.rejection_reason}
                                    </td>
                                    <td className="table-td">
                                      {item.mode_of_payment}
                                    </td>
                                    <td className="table-td">
                                      {item.cheque_number}
                                    </td>
                                    <td className="table-td">
                                      {item.neft_number}
                                    </td>
                                    <td className="table-td">
                                      {item?.expenditure_heads?.name}
                                    </td>
                                    <td className="table-td">
                                      {item?.bank_name}
                                    </td>
                                    <td className="table-td">
                                      <button
                                        className="disablebtn"
                                        style={{ display: "flex" }}
                                      >
                                        {isAdmin && (
                                          <div className="action-btn">
                                            <img
                                              className="deletebutton"
                                              src={deletebtn}
                                              alt="..."
                                              onClick={(e) =>
                                                handleDeleteEntry(item)
                                              }
                                            />
                                          </div>
                                        )}
                                        {role !== "View" && (
                                          <div className="action-btn">
                                            {role === "Admin" && (
                                              <img
                                                className="deletebutton"
                                                src={editbtn}
                                                alt="..."
                                                onClick={(e) =>
                                                  handleEdit(e, item)
                                                }
                                              />
                                            )}
                                            {role === "Operator" &&
                                              calculateDayDifference(
                                                item.voucher_date
                                              ) && (
                                                <img
                                                  className="deletebutton"
                                                  src={editbtn}
                                                  alt="..."
                                                  onClick={(e) =>
                                                    handleEdit(e, item)
                                                  }
                                                />
                                              )}
                                          </div>
                                        )}
                                        <div className="action-btn">
                                          <img
                                            className="deletebutton"
                                            src={downloadPdfImage}
                                            alt="..."
                                            onClick={(e) =>
                                              handledownload(item)
                                            }
                                          />
                                        </div>
                                      </button>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td className="text-center p-3" colSpan={16}>
                                No Records found...
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="d-flex align-items-center justify-content-between my-4">
                      <div>
                        <p>
                          showing {bankVoucherData.filteredCount} out of{" "}
                          {bankVoucherData.totalRecords}
                        </p>
                      </div>
                      <div>
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={bankVoucherData.totalPages}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageSubmit}
                          containerClassName={
                            "pagination justify-content-center"
                          }
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditModal
        show={showModal}
        hide={() => {
          setShowModal(false);
        }}
        key={selectedVoucher?.voucher_number}
        data={selectedVoucher}
        onSubmit={handleGetBankVoucherDetail}
        partyies={partyies}
        expenditure={expenditure}
      />

      <Confirmationmodal
        show={showConfirmationModal}
        hide={() => {
          setShowConfirmationModal(false);
        }}
        handleDeleteConfirmation={() => {
          handleConfirmation();
        }}
      />
      <DownloadPdfModal
        show={showModalPdf}
        hide={() => {
          setShowModalPdf(false);
        }}
        data={selectedRow}
      />
      {isLoading && <Loader />}
    </>
  );
}

export default BankVoucher;
