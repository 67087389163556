import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./modal.scss";
import crossbtn from "../../assets/images/delete-button.png";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import Loader from "../../helper/Loader/Loader";


const EditModal = (props) => {
  const { authStore } = useStores();
  const [name, setName] = useState(props?.data?.name);
  const [email, setEmail] = useState(props?.data?.email);
  const [password, setPassword] = useState("");
  const [contact, setContact] = useState(
    props?.data?.contact ? props?.data?.contact : null
  );
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(props?.data?.role_id);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    HandleRoleData();
  }, []);

  const HandleRoleData = async () => {
    setIsLoading(true)
    let payload = {
      limit: 10,
      filters: {},
      pageNumber: 1,
    };
    await authStore.getRoleData(
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          setRoles(response?.data?.data?.filteredData?.results);
          setIsLoading(false);
        } else {
          notify(response?.meta?.message, "danger");
          setIsLoading(false);
        }
      },
      (error) => {
        notify(error?.response?.meta?.message, "danger");
        setIsLoading(false);
      }
    );
  };

  const handleSave = async () => {
    setIsLoading(true)
    let payload = {
      name: name,
      email: email,
      role_id: +selectedRole,
    };

    if (password !== "") {
      payload.password = password
    }

    if (contact !== null || contact !== undefined) {
      payload.contact = contact;
    }
    props.hide();

    await authStore.editUserDetail(
      props.data.id,
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          notify(response?.data?.meta?.message, "success");
          setIsLoading(false)
          props.onSubmit();
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false)
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false)
      }
    );
  };

  return (
    <Modal {...props} centered className="sucess-content">
      <Modal.Body className="sucess-modal">
        <div className="">
          {isLoading && <Loader />}
          <h4 className="modal-heading">Edit User details</h4>
          <div className="moodal">
            <div
              className="crossbtn-container"
              onClick={() => props.hide()}
              style={{ textAlign: "right" }}
            >
              <img
                className="crossbutton"
                src={crossbtn}
                alt="..."
                style={{
                  width: "28px",
                  position: "absolute",
                  right: "10px",
                  top: "17px",
                }}
              />
            </div>
            <div className="addName-model">
              <label className="input-lable">Name</label>
              <br />
              <input
                class="form-control"
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => {
                  let data = e.target.value.toUpperCase();
                  setName(data)
                }}
              />
            </div>
            <div className="addName-model">
              <label className="input-lable">Email</label>
              <br />
              <input
                class="form-control"
                type="text"
                placeholder="Email"
                style={{textTransform:"none"}}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="addName-model">
              <label className="input-lable">Password</label>
              <br />
              <input
                class="form-control"
                style={{textTransform:"none"}}
                type="text"
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="addName-model">
              <label className="input-lable">Contact</label>
              <br />
              <input
                class="form-control"
                type="text"
                placeholder="Contact"
                value={contact}
                onChange={(e) => setContact(e.target.value)}
              />
            </div>
            <div className="addName-model">
              <label className="input-lable">Role</label>
              <select
                className="form-control"
                id="partyName"
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}
              >
                <option>select</option>

                {roles.map((role, index) => (
                  <option key={index} value={role.id}>
                    {role.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="text-right mt-3">
            <button className="submit-button text-right" onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditModal;
