import { makeAutoObservable } from "mobx";
import Service from "../shared/service/service";
import { error } from "jquery";

class authStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    async login(formData, callback, callbackerror) {
        await Service.post('login', formData, async (response) => {
            return callback(response);
        }).catch((error) => {
            return callbackerror(error);
        });
    };

    async parties(formData, callback, callbackerror) {
        await Service.post("all_parties", formData, async (response) => {
            return callback(response);
        }).catch((error) => {
            return callbackerror(error);
        });
    }

    async addParty(formData, callback, callbackerror) {
        await Service.post("parties", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }
    async editPary(id, formData, callback, callbackerror) {
        await Service.post(`parties/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }
    async deletePartyName(id, payload, callback, callbackerror) {
        await Service.delete(`parties/${id}`, payload, async (status, response) => {
            return callback(status, response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }

    async gethallDeatails(formData, callback, callbackerror) {
        await Service.post("all_halls", formData, async (response) => {
            return callback(response);
        }).catch((error) => {
            return callbackerror(error);
        });
    }
    async addHall(formData, callback, callbackerror) {
        await Service.post("halls", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }

    async editHalls(id, formData, callback, callbackerror) {
        await Service.post(`halls/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }
    async deleteHallName(id, payload, callback, callbackerror) {
        await Service.delete(`halls/${id}`, payload, async (status, response) => {
            return callback(status, response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }


    async createCashVoucher(formData, callback, callbackerror) {
        await Service.post("cash_vouchers", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getCashVoucher(formData, callback, callbackerror) {
        await Service.post("all_cash_vouchers", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }

    async trackCashVoucher(formData, callback, callbackerror) {
        await Service.post("deleted_cash_vouchers", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }

    async editCashVoucher(id, formData, callback, callbackerror) {
        await Service.post(`cash_vouchers/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }

    async deleteCashVoucher(id, payload, callback, callbackerror) {
        await Service.delete(`cash_vouchers/${id}`, payload, async (status, response) => {
            return callback(status, response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }

    async createBankVoucher(formData, callback, callbackerror) {
        await Service.post("bank_vouchers", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }

    async getBankVoucher(formData, callback, callbackerror) {
        await Service.post("all_bank_vouchers", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }
    async trackBankVoucher(formData, callback, callbackerror) {
        await Service.post("deleted_bank_vouchers", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }
    async editBankVoucher(id, formData, callback, callbackerror) {
        await Service.post(`bank_vouchers/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }


    async deleteBankVoucher(id, payload, callback, callbackerror) {
        await Service.delete(`bank_vouchers/${id}`, payload, async (status, response) => {
            return callback(status, response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }

    async createRecieptVoucher(formData, callback, callbackerror) {
        await Service.post("reciept_vouchers", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }

    async getRecieptVoucher(formData, callback, callbackerror) {
        await Service.post("all_reciept_vouchers", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }
    async trackRecieptVoucher(formData, callback, callbackerror) {
        await Service.post("deleted_reciept_vouchers", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }


    async editRecieptVoucher(id, formData, callback, callbackerror) {
        await Service.post(`reciept_vouchers/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }

    async deleteReceiptVoucher(id, payload, callback, callbackerror) {
        await Service.delete(`reciept_vouchers/${id}`, payload, async (status, response) => {
            return callback(status, response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }

    async createRefundVoucher(formData, callback, callbackerror) {
        await Service.post("refund_vouchers", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }
    async getRefundVoucher(formData, callback, callbackerror) {
        await Service.post("all_refund_vouchers", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }

    async editRefundVoucher(id, formData, callback, callbackerror) {
        await Service.post(`refund_vouchers/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }


    async deleteRefundVoucher(id, callback, callbackerror) {
        await Service.delete(`refund_vouchers/${id}`, async (status, response) => {
            return callback(status, response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }

    async getRoleData(formData, callback, callbackerror) {
        await Service.post("all_roles", formData, async (response) => {
            return callback(response);
        }).catch((error) => {
            return callbackerror(error);
        });
    }

    async addRole(formData, callback, callbackerror) {
        await Service.post("roles", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }

    async createUserDetails(formData, callback, callbackerror) {
        await Service.post("users", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }

    async getUsersDetails(formData, callback, callbackerror) {
        await Service.post("all_users", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }

    async editUserDetail(id, formData, callback, callbackerror) {
        await Service.post(`users/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }

    async changePassword(id, formData, callback, callbackerror) {
        await Service.post(`password/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }

    async getexpenditure(formData, callback, callbackerror) {
        await Service.post("all_expenditure_heads", formData, async (response) => {
            return callback(response);
        }).catch((error) => {
            return callbackerror(error);
        });
    }
    async addExpenditure(formData, callback, callbackerror) {
        await Service.post("expenditure_heads", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }

    async deleteExpenditure(id, payload, callback, callbackerror) {
        await Service.delete(`expenditure_heads/${id}`, payload, async (status, response) => {
            return callback(status, response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }
    async editExpenditure(id, formData, callback, callbackerror) {
        await Service.post(`expenditure_heads/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }

    async partiesList(callback, callbackerror) {
        await Service.get("parties", async (status, response) => {
            return callback(response);
        }).catch((error) => {
            return callbackerror(error);
        });
    }

    async expenditureList(callback, callbackerror) {
        await Service.get("expenditure_heads", async (status, response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error)
        })
    }

    async hallList(callback, callbackerror) {
        await Service.get("halls", async (status, response) => {
            return callback(response);
        }).catch((error) => {
            return callbackerror(error);
        });
    }
}


export default authStore;
