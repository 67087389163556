import React, { useState } from "react";
import "./style.scss";
import Modal from 'react-bootstrap/Modal';

function Loader(props) {
    const [show, setShow] = useState(true);

    return (
        <Modal className="custom-loader" show={show}>
            <Modal.Body>
                <div className="spinner-container">
                    <div className="loading-spinner">
                    </div>
                    <div className="loading-text">
                        Loading...
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default Loader;