import React, { useEffect, useState, useRef } from "react";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import moment from "moment";
import "./style.scss";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import deletebtn from "../../assets/images/trash (1).png";
import editbtn from "../../assets/images/edit (3).png";
import EditModal from "../../components/Modal/RecieptEditModal";
import ConfirmationModal from "../../components/Modal/RecieptConfimationModal";
import calendar from "../../assets/images/calendar.png";
import Loader from "../../helper/Loader/Loader";
import ReactExport from "react-export-excel";
import downloadPdfImage from "../../assets/images/download.png";
import DownloadPdf from "../../components/Modal/DownloadPdfModal";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const limit = [
  {
    option: 2,
    value: 2,
  },
  {
    option: 10,
    value: 10,
  },
  {
    option: 100,
    value: 100,
  },
  {
    option: 500,
    value: 500,
  },
  {
    option: 1000,
    value: 1000,
  },
];

const ReceiptVoucher = () => {
  const startCalendarRef = useRef();
  const endCalendarRef = useRef();
  const partyStartCalendarRef = useRef();
  const partyEndCalendarRef = useRef();
  const { authStore } = useStores();
  const userId = sessionStorage.getItem("id");
  const date = sessionStorage.getItem("date");
  let currentDate = sessionStorage.getItem("date");
  // currentDate = moment(new Date(currentDate)).format("DD-MM-YYYY");
  if (currentDate) {
    currentDate = moment(currentDate, "DD-MM-YYYY").format("YYYY-MM-DD");
  }
  const [partyies, setPartyies] = useState([]);
  const [selectedParty, setSelectedParty] = useState("");
  const [selectedHall, setSelectedHall] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [addedName, setAddedName] = useState("");
  const [addedHallName, setAddedHallName] = useState("");
  const [show, setShow] = useState(true);
  const createdd = sessionStorage.getItem("id");
  const [banquetNumber, setBanquetNumber] = useState();
  const [expectedPax, setExpectedPax] = useState();
  const [particular, setParticular] = useState();
  const [partyDate, setPartyDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [cashAmount, setCashAmount] = useState();
  const [cardAmount, setCardAmount] = useState();
  const [bankAmount, setBankAmount] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [recieptData, setRecieptData] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalPdf, setShowModalPdf] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [selectedVoucher, setSelectedVoucher] = useState();
  const [vouvherNum, setVoucherNum] = useState();
  const [hallDetail, setHallDetail] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [filterVoucherNumber, setFilterVoucherNumber] = useState("");
  const [filterparty, setFilterParty] = useState("");
  const [partyStartDate, setPartyStartDate] = useState();
  const [partyEndDate, setPartyEndDate] = useState();
  const [filterBanquetNumber, setFilterBanquetNumber] = useState("");
  const [filterHall, setFilterHall] = useState("");
  // const [filteramount, setFilterAmount] = useState("")
  const [pageLimit, setPageLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [recieptVoucherData, setRecieptVoucherData] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const role = sessionStorage.getItem("role");
  const [partyContact, setPartyContact] = useState();
  const [voucherDate, setVoucherDate] = useState(currentDate);
  const [transactionId , setTransactionId] = useState("")

  useEffect(() => {
    setIsAdmin(role === "Admin");
    handleparty();
    handleHallsDetail();
    handleGetRecieptVoucherDetail();
  }, []);

  const handleparty = async () => {
    setIsLoading(true);
    await authStore.partiesList(
      (response) => {
        if (response?.meta?.status === "success") {
          let data = [...response?.data?.parties];
          let obj = {
            id: "0",
            name: "Other",
          };
          data.push(obj);
          const selectOptions = data.map((option) => ({
            value: option.id,
            label: option.name,
          }));
          setPartyies(selectOptions);
          setIsLoading(false);
        } else {
          notify(response?.meta?.message, "danger");
          setIsLoading(false);
        }
      },
      (error) => {
        notify(error?.response?.meta?.message, "danger");
        setIsLoading(false);
      }
    );
  };

  const handleHallsDetail = async () => {
    setIsLoading(true);
    await authStore.hallList(
      (response) => {
        if (response?.meta?.status === "success") {
          let data = [...response?.data?.halls];
          let obj = {
            id: "0",
            name: "Other",
          };
          data.push(obj);
          const selectOptions = data.map((option) => ({
            value: option.id,
            label: option.name,
          }));
          setHallDetail(selectOptions);
          setIsLoading(false);
        } else {
          notify(response?.meta?.message, "danger");
          setIsLoading(false);
        }
      },
      (error) => {
        notify(error?.response?.meta?.message, "danger");
        setIsLoading(false);
      }
    );
  };
  const handleAddCustomOption = async () => {
    setIsLoading(true);
    setShow(false);
    let payload = {
      name: addedName,
    };
    await authStore.addParty(
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          notify(response?.data?.meta?.message, "success");
          setIsLoading(false);
          handleparty();
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false);
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false);
      }
    );
  };

  const handleAddHallOption = async () => {
    setIsLoading(true);
    setShow(false);
    let payload = {
      name: addedHallName,
    };
    await authStore.addHall(
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          notify(response?.data?.meta?.message, "success");
          setIsLoading(false);
          handleHallsDetail();
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false);
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false);
      }
    );
  };

  const handlePartyNameChange = (event) => {
    const selectedValue = event;
    setSelectedParty(selectedValue);
  };
  const handleHallNameChange = (event) => {
    setSelectedHall(event);
  };

  const handleCreateReceiptVoucher = async () => {
    debugger
    setIsLoading(true);
    let payload = {
      partyId: selectedParty?.value,
      particulars: particular,
      cashAmount: cashAmount,
      createdBy: createdd,
      cardAmount: cardAmount,
      bankAmount: bankAmount,
      totalAmount: totalAmount,
      partyDate: partyDate,
      hallId: selectedHall?.value,
      transactionId : transactionId !== "" && transactionId !== undefined && transactionId !== null ? transactionId : undefined
    };

    if (
      expectedPax !== null &&
      expectedPax !== undefined &&
      expectedPax !== ""
    ) {
      payload.pax = expectedPax;
    }
    if (
      banquetNumber !== null &&
      banquetNumber !== undefined &&
      banquetNumber !== ""
    ) {
      payload.banquetNumber = banquetNumber;
    }

    if (
      partyContact !== null &&
      partyContact !== undefined &&
      partyContact !== ""
    ) {
      payload.guestContact = partyContact;
    }

    if (role === "Admin") {
      payload.voucherDate = voucherDate;
    }
    await authStore.createRecieptVoucher(
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          notify(response?.data?.meta?.message, "success");
          setIsLoading(false);
          handleGetRecieptVoucherDetail();
          setPartyDate("")
          setBanquetNumber("")
          setExpectedPax("")
          setParticular("")
          setCashAmount("")
          setCardAmount("")
          setBankAmount("")
          setTotalAmount("")
          setHallDetail("")
          setPartyContact("")
          setSelectedParty("")
          setTransactionId("")
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false);
          setPartyDate("")
          setBanquetNumber("")
          setExpectedPax("")
          setParticular("")
          setCashAmount("")
          setCardAmount("")
          setBankAmount("")
          setTotalAmount("")
          setSelectedHall("")
          setPartyContact("")
          setSelectedParty("")
          setTransactionId("")
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false);
        setPartyDate("")
        setBanquetNumber("")
        setExpectedPax("")
        setParticular("")
        setCashAmount("")
        setCardAmount("")
        setBankAmount("")
        setTotalAmount("")
        setSelectedHall("")
        setPartyContact("")
        setSelectedParty("")
        setTransactionId("")
      }
    );
  };

  const handleGetRecieptVoucherDetail = async () => {
    setIsLoading(true);
    let payload = {
      limit: 10,
      filters: {},
      pageNumber: 1,
    };
    callApi(payload);
  };

  const handleEdit = (e, item) => {
    e.preventDefault();
    setSelectedVoucher(item);
    setShowModal(true);
  };

  const handleDeleteEntry = async (item) => {
    setShowConfirmationModal(true);
    setVoucherNum(item?.voucher_number);
  };

  const handleConfirmation = async () => {
    let payload = {
      userId: userId,
    };
    await authStore.deleteReceiptVoucher(
      vouvherNum,
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          notify(response?.data?.meta?.message);
          setShowConfirmationModal(false);
          handleGetRecieptVoucherDetail();
        } else {
          notify(response?.data?.meta?.message, "danger");
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
      }
    );
  };

  const handleStartDate = (date) => {
    setStartDate(date);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
  };

  const handlePartyStartDate = (date) => {
    setPartyStartDate(date);
  };

  const handlePartyEndDate = (date) => {
    setPartyEndDate(date);
  };

  const openDatepicker1 = () => {
    startCalendarRef.current.setFocus(true);
  };

  const openDatepicker2 = () => {
    endCalendarRef.current.setFocus(true);
  };
  const openDatepicker3 = () => {
    partyStartCalendarRef.current.setFocus(true);
  };

  const openDatepicker4 = () => {
    partyEndCalendarRef.current.setFocus(true);
  };

  const handleFilter = async () => {
    let newStartDate = startDate
      ? moment(new Date(startDate)).format("YYYY-MM-DD")
      : null;
    let newEndDate = endDate
      ? moment(new Date(endDate)).format("YYYY-MM-DD")
      : null;

    let newPartyStartDate = partyStartDate
      ? moment(new Date(partyStartDate)).format("YYYY-MM-DD")
      : null;
    let newPartyEndDate = partyEndDate
      ? moment(new Date(partyEndDate)).format("YYYY-MM-DD")
      : null;

    let payload = {
      limit: pageLimit,
      filters: {
        voucherNumber: filterVoucherNumber,
        voucherFromDate: newStartDate,
        voucherToDate: newEndDate,
        partyName: filterparty,
        partyFromDate: newPartyStartDate,
        partyToDate: newPartyEndDate,
        banquetNumber: filterBanquetNumber,
        hall: filterHall,
        // amount: filteramount
      },
      pageNumber: page,
    };
    callApi(payload);
  };

  const handleReset = async () => {
    // setFilterAmount("");
    setFilterParty("");
    setFilterVoucherNumber("");
    setPartyStartDate(null);
    setPartyEndDate(null);
    setFilterBanquetNumber("");
    setFilterHall("");
    setPage(1);
    setPageLimit(10);
    startCalendarRef.current.value = "";
    endCalendarRef.current.value = "";
    setStartDate(null);
    setEndDate(null);
    await handleGetRecieptVoucherDetail();
  };

  const handlePageSubmit = async (index) => {
    setPage(index.selected + 1);
    let newStartDate = startDate
      ? moment(new Date(startDate)).format("YYYY-MM-DD")
      : null;
    let newEndDate = endDate
      ? moment(new Date(endDate)).format("YYYY-MM-DD")
      : null;

    let newPartyStartDate = partyStartDate
      ? moment(new Date(partyStartDate)).format("YYYY-MM-DD")
      : null;
    let newPartyEndDate = partyEndDate
      ? moment(new Date(partyEndDate)).format("YYYY-MM-DD")
      : null;

    let payload = {
      limit: pageLimit,
      filters: {
        voucherNumber: filterVoucherNumber,
        voucherFromDate: newStartDate,
        voucherToDate: newEndDate,
        partyName: filterparty,
        partyFromDate: newPartyStartDate,
        partyToDate: newPartyEndDate,
        banquetNumber: filterBanquetNumber,
        hall: filterHall,
        // amount: filteramount
      },
      pageNumber: index.selected + 1,
    };
    callApi(payload);
  };

  const limitChangeHandler = async (index) => {
    setPageLimit(index);
    let newStartDate = startDate
      ? moment(new Date(startDate)).format("YYYY-MM-DD")
      : null;
    let newEndDate = endDate
      ? moment(new Date(endDate)).format("YYYY-MM-DD")
      : null;

    let newPartyStartDate = partyStartDate
      ? moment(new Date(partyStartDate)).format("YYYY-MM-DD")
      : null;
    let newPartyEndDate = partyEndDate
      ? moment(new Date(partyEndDate)).format("YYYY-MM-DD")
      : null;

    let payload = {
      limit: index,
      filters: {
        voucherNumber: filterVoucherNumber,
        voucherFromDate: newStartDate,
        voucherToDate: newEndDate,
        partyName: filterparty,
        partyFromDate: newPartyStartDate,
        partyToDate: newPartyEndDate,
        banquetNumber: filterBanquetNumber,
        hall: filterHall,
        // amount: filteramount
      },
      pageNumber: page,
    };
    callApi(payload);
  };

  const callApi = async (payload) => {
    await authStore.getRecieptVoucher(
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          setRecieptVoucherData(response?.data?.data);
          setRecieptData(response?.data?.data?.filteredData?.results);
          setIsLoading(false);
        } else {
          notify(response?.data?.meta?.message);
          setIsLoading(false);
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message);
        setIsLoading(false);
      }
    );
  };
  const handledownload = (item) => {
    setShowModalPdf(true);
    let {
      voucher_number,
      voucher_date,
      parties,
      party_date,
      banquet_number,
      pax,
      particulars,
      cash_amount,
      card_amount,
      bank_amount,
      total_amount,
      halls,
      transaction_id,
      guest_contact, users,
    } = item;
    setSelectedRow({
      "Voucher Number": voucher_number,
      Date:
        voucher_date &&
        new Date(voucher_date.split("T")[0]).toLocaleDateString("en-GB"),
      "Party Name": parties?.name,
      "Party/Check in Date":
        party_date &&
        new Date(party_date.split("T")[0]).toLocaleDateString("en-GB"),
      "Banquet/Reservation Number": banquet_number,
      Pax: pax,
      "Particulars/Guest Name": particulars,
      "Cash Amount": cash_amount,
      "Card Amount": card_amount,
      "Bank Amount": bank_amount,
      "Total Amount": total_amount,
      Hall: halls?.name,
      "Guest Contact": guest_contact,
      "Transaction ID" : transaction_id,
      "Created By": users?.name,
      "Approver Signature": "",
      "Counter Party Signature": "",
    });
  };

  const updateTotalAmount = () => {
    const cash = parseFloat(cashAmount) || 0;
    const card = parseFloat(cardAmount) || 0;
    const bank = parseFloat(bankAmount) || 0;
    const total = cash + card + bank;
    setTotalAmount(total.toFixed(2)); // You can adjust the decimal places as needed
  };
  const calculateDayDifference = (givenDate) => {
    const parsedGivenDate = new Date(givenDate);
    let currDate = new Date();
    const timeDifference = currDate - parsedGivenDate.getTime();
    const daysDifference = timeDifference / (1000 * 3600 * 24);
    return daysDifference <= 10;
  };

  const handleMenuOpen = () => {
    setSelectedParty("");
  };
  const handleHallMenuOpen = () => {
    setSelectedHall("");
  };

  return (
    <>
      <div className="leaddetails-container">
        <div className="RightArea">
          <div className="dashboard-area">
            <div className="container-fluid dash-container">
              <div className="needs-validation">
                <div className="row">
                  <div className="col-md-12">
                    <div className="hall-header">
                      <h2 className="sub-heading">Receipt Voucher</h2>
                    </div>
                  </div>
                  <div className="receipt-form-box">
                    <div className="row">
                      <div className="col-md-3">
                        <label className="input-lable">
                          Voucher Date<span className="required-field">*</span>
                        </label>
                        <input
                          readOnly
                          type="text"
                          value={date}
                          className="form-control"
                          id="last name"
                          placeholder={date}
                        />
                        {/* )} */}
                      </div>
                      <div className="col-md-3">
                        <label className="input-lable" for="partyName">
                          Name of Party<span className="required-field">*</span>
                        </label>
                        <Select
                          value={selectedParty}
                          onChange={handlePartyNameChange}
                          options={partyies}
                          isSearchable={true}
                          placeholder="search for party"
                          onMenuOpen={handleMenuOpen}
                        />
                      </div>
                      {selectedParty?.label === "Other" && show && (
                        <div className="col-md-3">
                          <label className="input-lable">Add Name</label>
                          <div style={{ position: "relative" }}>
                            <input
                              type="text"
                              className="form-control"
                              id="customName"
                              placeholder="Type other name"
                              value={addedName}
                              onChange={(e) => {
                                let data = e.target.value.toUpperCase();
                                setAddedName(data)
                              }}
                            />
                            <button
                              className="add-button"
                              onClick={handleAddCustomOption}
                            >
                              Add Name
                            </button>
                          </div>
                        </div>
                      )}
                      <div className="col-md-3">
                        <label className="input-lable">Party/Check in Date</label>
                        <input
                          type="date"
                          className="form-control"
                          id="partyDate"
                          placeholder={Date}
                          value={partyDate}
                          onChange={(e) => setPartyDate(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="input-lable">Banquet/Reservation Number</label>
                        <input
                          className="form-control"
                          id="banqutNumber"
                          placeholder="Banquet Number"
                          value={banquetNumber}
                          onChange={(e) => setBanquetNumber(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="input-lable">Expected PAX</label>
                        <input
                          type="text"
                          className="form-control"
                          id="expectedPax"
                          placeholder="Expected PAX"
                          value={expectedPax}
                          onChange={(e) => setExpectedPax(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="input-lable">
                        Particulars/Guest Name<span className="required-field">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="Particulars"
                          placeholder="Particulars"
                          value={particular?.toUpperCase()}
                          onChange={(e) => {
                            let data = e.target.value.toUpperCase();
                            setParticular(data)
                          }}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="input-lable">
                          Cash Amount<span className="required-field">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="cashAmmount"
                          placeholder="Cash Amount"
                          value={cashAmount}
                          onChange={(e) => setCashAmount(e.target.value)}
                          onBlur={updateTotalAmount}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="input-lable">
                          Card Amount<span className="required-field">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="cardAmmount"
                          placeholder="Card Amount"
                          value={cardAmount}
                          onChange={(e) => setCardAmount(e.target.value)}
                          onBlur={updateTotalAmount}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="input-lable">
                          Bank Amount<span className="required-field">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="bankAmmount"
                          placeholder="Bank Amount"
                          value={bankAmount}
                          onChange={(e) => setBankAmount(e.target.value)}
                          onBlur={updateTotalAmount}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="input-lable">
                          Total Amount<span className="required-field">*</span>
                        </label>
                        <input
                          readOnly
                          type="text"
                          className="form-control"
                          id="bankAmmount"
                          placeholder="Total Amount"
                          value={totalAmount}
                          onChange={(e) => setTotalAmount(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="input-lable" for="partyName">
                          Hall<span className="required-field">*</span>
                        </label>
                        <Select
                          value={selectedHall}
                          onChange={handleHallNameChange}
                          options={hallDetail}
                          isSearchable={true}
                          placeholder="search for hall"
                          onMenuOpen={handleHallMenuOpen}
                        />
                      </div>
                      {selectedHall?.label === "Other" && show && (
                        <div className="col-md-3">
                          <label className="input-lable">Add Name</label>
                          <div style={{ position: "relative" }}>
                            <input
                              type="text"
                              className="form-control"
                              id="customName"
                              placeholder="Type other name"
                              value={addedHallName}
                              onChange={(e) => {
                                let data = e.target.value.toUpperCase();
                                setAddedHallName(data)
                              }}
                            />
                            <button
                              className="add-button"
                              onClick={handleAddHallOption}
                            >
                              Add Name
                            </button>
                          </div>
                        </div>
                      )}
                      <div className="col-md-3">
                        <label className="input-lable">
                          Guest Contact
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="partyContact"
                          placeholder="Party Contact"
                          value={partyContact}
                          maxLength={10}
                          onChange={(e) => setPartyContact(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="input-lable">
                        Transaction ID
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Transaction ID"
                          value={transactionId}
                          onChange={(e) =>{
                            let data = e.target.value.toUpperCase();
                            setTransactionId(data)
                          } }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12" style={{ textAlign: "right" }}>
                    <button
                      className="submit-button"
                      type="submit"
                      onClick={handleCreateReceiptVoucher}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
              <div className="needs-validation">
                <div className="row">
                  <div className="col-md-12">
                    <div className="hall-header">
                      <h2 className="sub-heading">Entries</h2>
                    </div>
                  </div>
                  <div className="col-md-12 filter-container">
                    <div className="calendar-container1">
                      <DatePicker
                        className="form-control"
                        placeholderText="From Date"
                        selected={startDate}
                        onChange={(date) => {
                          handleStartDate(date);
                        }} //only when value has changed
                        dateFormat="dd-MM-yyyy"
                        ref={startCalendarRef}
                      />
                      <div className="calendar-icon">
                        <img
                          src={calendar}
                          alt="fromcal"
                          width="20px"
                          onClick={openDatepicker1}
                        />
                      </div>
                    </div>
                    <div className="calendar-container1">
                      <DatePicker
                        className="form-control"
                        placeholderText="To Date"
                        selected={endDate}
                        onChange={(date) => {
                          handleEndDate(date);
                        }} //only when value has changed
                        dateFormat="dd-MM-yyyy"
                        ref={endCalendarRef}
                      />
                      <div className="calendar-icon">
                        <img
                          src={calendar}
                          alt="tocal"
                          width="20px"
                          onClick={openDatepicker2}
                        />
                      </div>
                    </div>
                    <div>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Voucher Number"
                        value={filterVoucherNumber}
                        onChange={(e) => setFilterVoucherNumber(e.target.value)}
                      />
                    </div>
                    <div>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Party Name"
                        value={filterparty?.toUpperCase()}
                        onChange={(e) => setFilterParty(e.target.value)}
                      />
                    </div>
                    <div className="calendar-container1">
                      <DatePicker
                        className="form-control"
                        placeholderText="Party Date"
                        selected={partyStartDate}
                        onChange={(date) => {
                          handlePartyStartDate(date);
                        }} //only when value has changed
                        dateFormat="dd-MM-yyyy"
                        ref={partyStartCalendarRef}
                      />
                      <div className="calendar-icon">
                        <img
                          src={calendar}
                          alt="fromcal"
                          width="20px"
                          onClick={openDatepicker3}
                        />
                      </div>
                    </div>
                    <div className="calendar-container1">
                      <DatePicker
                        className="form-control"
                        placeholderText="To Date"
                        selected={partyEndDate}
                        onChange={(date) => {
                          handlePartyEndDate(date);
                        }} //only when value has changed
                        dateFormat="dd-MM-yyyy"
                        ref={partyEndCalendarRef}
                      />
                      <div className="calendar-icon">
                        <img
                          src={calendar}
                          alt="tocal"
                          width="20px"
                          onClick={openDatepicker4}
                        />
                      </div>
                    </div>
                    <div>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Banquet Number"
                        value={filterBanquetNumber}
                        onChange={(e) => setFilterBanquetNumber(e.target.value)}
                      />
                    </div>
                    <div>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Hall"
                        value={filterHall?.toUpperCase()}
                        onChange={(e) => setFilterHall(e.target.value)}
                      />
                    </div>
                    <div className="btn-container">
                      <button
                        className="submit-button"
                        type="submit"
                        onClick={handleFilter}
                      >
                        Filter
                      </button>
                      <button
                        className="submit-button"
                        type="submit"
                        onClick={handleReset}
                      >
                        Reset
                      </button>
                      {recieptData?.length > 0 && (
                        <button className="submit-button">
                          <ExcelFile element={<span style={{ cursor: "pointer" }}  > Export  </span>} >
                            <ExcelSheet
                              data={recieptData?.map((item, i) => ({
                                ...item,
                                index: i + 1,
                                voucher_date: new Date(
                                  item.voucher_date
                                ).toLocaleDateString("en-GB", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "2-digit",
                                }),
                                party_date: new Date(
                                  item.party_date
                                ).toLocaleDateString("en-GB", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "2-digit",
                                }),

                              }))}
                              name="Sheet"
                            >
                              <ExcelColumn label="Sr.No" value="index" />
                              <ExcelColumn
                                label="Voucher No."
                                value="voucher_number"
                              />
                              <ExcelColumn
                                label="Voucher Date"
                                value="voucher_date"
                              />
                              <ExcelColumn
                                label="Name of Party"
                                value={(item) => item?.parties?.name?.toUpperCase()}
                              />
                              <ExcelColumn
                                label="Party Date"
                                value="party_date"
                              />
                              <ExcelColumn
                                label="Banquet Number"
                                value="banquet_number"
                              />
                              <ExcelColumn label="Expected PAX" value="pax" />
                              <ExcelColumn
                                label="Particulars"
                                // value="particulars"
                                value={(item) => item?.particulars?.toUpperCase()}
                              />
                              <ExcelColumn
                                label="Cash Amount	"
                                value="cash_amount"
                              />
                              <ExcelColumn
                                label="Card Amount	"
                                value="card_amount"
                              />
                              <ExcelColumn
                                label="Bank Amount	"
                                value="bank_amount"
                              />
                              <ExcelColumn
                                label="Total Amount	"
                                value="total_amount"
                              />
                              <ExcelColumn
                                label="Hall"
                                value={(item) => item?.halls?.name?.toUpperCase()}
                              />
                              <ExcelColumn
                                label="Guest No."
                                value={(item) => item?.guest_contact}
                              /><ExcelColumn
                              label="Transaction ID"
                              value={(item) => item?.transaction_id?.toUpperCase()}
                            />
                            </ExcelSheet>
                          </ExcelFile>
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div>
                      <label className="d-flex align-items-center p-0 col-md-2">
                        {page === 1 ? (
                          <select
                            value={pageLimit}
                            className="form-control selectbox-container mr-2"
                            onChange={(e) => limitChangeHandler(e.target.value)}
                          >
                            {limit.length > 0
                              ? limit.map((item, index) => (
                                <option key={index} value={item.option}>
                                  {item.value}
                                </option>
                              ))
                              : null}
                          </select>
                        ) : (
                          <p className="form-control selectbox-container mx-2 my-0">
                            {pageLimit}
                          </p>
                        )}
                        <span> entries</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12 listing-table">
                    <div className="data-table  py-4 table-responsive">
                      <table className="details-box table-bordered ">
                        <thead className="thead-box">
                          <tr className="thead">
                            <th className="action">Sr.No</th>
                            <th className="action action-res">Voucher No.</th>
                            <th className="action action-res">Voucher Date</th>
                            <th className="name action-res">Name of Party</th>
                            <th className="name action-res">Party/Check in Date</th>
                            <th className="name action-res">Banquet/Reservation Number</th>
                            <th className="name action-res">PAX</th>
                            <th className="name action-res">Particulars/Guest Name</th>
                            <th className="name action-res">Cash Amount</th>
                            <th className="name action-res">Card Amount</th>
                            <th className="name action-res">Bank Amount</th>
                            <th className="name action-res">Total Amount</th>
                            <th className="name action-res">Hall</th>
                            <th className="name action-res">Guest No.</th>
                            <th className="name action-res">Transaction ID</th>
                            <th className="action">Action</th>
                          </tr>
                        </thead>
                        {recieptData?.length > 0 ? (
                          <tbody>
                            {recieptData.map((item, i) => {
                              return (
                                <>
                                  <tr key={item?.voucher_number}>
                                    <td className="table-td">{i + 1}</td>
                                    <td className="table-td">
                                      {item.voucher_number}
                                    </td>
                                    <td className="table-td">
                                      {item?.voucher_date &&
                                        new Date(
                                          item.voucher_date
                                        ).toLocaleDateString("en-GB")}
                                    </td>
                                    <td className="table-td">
                                      {item?.parties?.name}
                                    </td>
                                    <td className="table-td">
                                      {item.party_date &&
                                        new Date(
                                          item.party_date
                                        ).toLocaleDateString("en-GB")}
                                    </td>
                                    <td className="table-td">
                                      {item?.banquet_number}
                                    </td>
                                    <td className="table-td">{item.pax}</td>
                                    <td className="table-td">
                                      {item.particulars}
                                    </td>
                                    <td className="table-td">
                                      {item.cash_amount}
                                    </td>
                                    <td className="table-td">
                                      {item.card_amount}
                                    </td>
                                    <td className="table-td">
                                      {item.bank_amount}
                                    </td>
                                    <td className="table-td">
                                      {item.total_amount}
                                    </td>
                                    <td className="table-td"> {item.halls?.name}</td>
                                    <td className="table-td">
                                      {item.guest_contact}
                                    </td>
                                    <td className="table-td">
                                      {item?.transaction_id}
                                    </td>
                                    <td className="table-td">
                                      <button
                                        className="disablebtn"
                                        style={{ display: "flex" }}
                                      >
                                        {isAdmin && (
                                          <div className="action-btn">
                                            <img
                                              className="deletebutton"
                                              src={deletebtn}
                                              alt="..."
                                              onClick={(e) =>
                                                handleDeleteEntry(item)
                                              }
                                            />
                                          </div>
                                        )}
                                        {role !== "View" && (
                                          <div className="action-btn">
                                            {role === "Admin" && (
                                              <img
                                                className="deletebutton"
                                                src={editbtn}
                                                alt="..."
                                                onClick={(e) =>
                                                  handleEdit(e, item)
                                                }
                                              />
                                            )}
                                            {role === "Operator" &&
                                              calculateDayDifference(
                                                item.voucher_date
                                              ) && (
                                                <img
                                                  className="deletebutton"
                                                  src={editbtn}
                                                  alt="..."
                                                  onClick={(e) =>
                                                    handleEdit(e, item)
                                                  }
                                                />
                                              )}
                                          </div>
                                        )}
                                        <div className="action-btn">
                                          <img
                                            className="deletebutton"
                                            src={downloadPdfImage}
                                            alt="..."
                                            onClick={(e) =>
                                              handledownload(item)
                                            }
                                          />
                                        </div>
                                      </button>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td className="text-center p-3" colSpan={16}>
                                No Records found...
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="d-flex align-items-center justify-content-between my-4">
                      <div>
                        <p>
                          showing {recieptVoucherData.filteredCount} out of
                          {recieptVoucherData.totalRecords}
                        </p>
                      </div>
                      <div>
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={recieptVoucherData.totalPages}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageSubmit}
                          containerClassName={
                            "pagination justify-content-center"
                          }
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditModal
        show={showModal}
        hide={() => {
          setShowModal(false);
        }}
        key={selectedVoucher?.voucher_number}
        data={selectedVoucher}
        onSubmit={handleGetRecieptVoucherDetail}
        partyies={partyies}
        hallDetail={hallDetail}
      />
      <ConfirmationModal
        show={showConfirmationModal}
        hide={() => {
          setShowConfirmationModal(false);
        }}
        handleDeleteConfirmation={() => {
          handleConfirmation();
        }}
      />
      <DownloadPdf
        show={showModalPdf}
        hide={() => {
          setShowModalPdf(false);
        }}
        data={selectedRow}
      />
      {isLoading && <Loader />}
    </>
  );
};

export default ReceiptVoucher;
