import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./modal.scss";
import crossbtn from "../../assets/images/delete-button.png";
import { useStores } from "../../stores";
import notify from "../../shared/notify";

const EditModal = (props) => {
  const { authStore } = useStores();
  const role = sessionStorage.getItem("role");
  const createdBy = sessionStorage.getItem("id");
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");

  const handleSave = async () => {
    let payload = {
      password,
      updatedBy: +createdBy,
    };
    await authStore.changePassword(
      createdBy,
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          notify(response?.data?.meta?.message, "success");
          setPassword("");
          props.onClose();
        } else {
          notify(response?.data?.meta?.message, "danger");
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
      }
    );
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Modal {...props} centered className="sucess-content">
      <Modal.Body className="sucess-modal">
        <div className="">
          <h4 className="modal-heading">Edit Password</h4>
          <div className="moodal">
            <div
              className="crossbtn-container"
              onClick={() => props.hide()}
              style={{ textAlign: "right" }}
            >
              <img
                className="crossbutton"
                src={crossbtn}
                alt="..."
                style={{
                  width: "28px",
                  position: "absolute",
                  right: "10px",
                  top: "17px",
                }}
              />
            </div>
            {/* <div className="addName-model">
              <label className="input-lable">Password</label>
              <br />
              <input
                class="form-control"
                type="password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div> */}
            <div className="addName-model">
              <label className="input-lable">Password</label>
              <br />
              <div className="password-input-container">
                <input
                  className="form-control"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div className="eye-icon">
                  <div type="button" onClick={togglePasswordVisibility} className="password-hideshow">
                    {showPassword  ? (
                      <img src={require("../../assets/images/eye-solid.png")} />
                    ) : (
                      <img
                        src={require("../../assets/images/eye-slash-solid.png")}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-right mt-3">
            <button className="submit-button text-right" onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditModal;
