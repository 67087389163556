import React from 'react'
import authStore from './authStore'


class RootStore {
  constructor() {
    
    this.authStore = new authStore(this)
  }
}


const StoresContext = React.createContext(new RootStore());

export const useStores = () => React.useContext(StoresContext);