import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import "./modal.scss";
import crossbtn from "../../assets/images/delete-button.png";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import Loader from "../../helper/Loader/Loader";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calendar from "../../assets/images/calendar.png";

const EditModal = (props) => {
  const startCalendarRef = useRef();
  const { authStore } = useStores();
  const role = sessionStorage.getItem("role");
  const createdBy = sessionStorage.getItem("id");
  const [voucher, setVoucher] = useState(props?.data?.voucher_number);
  const [nameParty, setNameParty] = useState(props?.data?.parties);
  const [particular, setParticular] = useState(props?.data?.particulars);
  const [amount, setAmount] = useState(props?.data?.amount);
  const [bankName , setBankName] = useState(props?.data?.bank_name)
  const [approvedAmount, setApprovedAmount] = useState(
    props?.data?.approved_amount
  );
  const [rejectedAmount, setRejectedAmount] = useState(
    props?.data?.rejected_amount
  );
  const [tdsDeducted, setTdsDeducted] = useState(props?.data?.deducted_tds);
  const [rejectionReason, setRejectionReason] = useState(
    props?.data?.rejection_reason
  );
  const [mode, setMode] = useState(props?.data?.mode_of_payment);
  const [chequeNumber, setChequeNumber] = useState(props?.data?.cheque_number);
  const [neftNumber, setNeftNumber] = useState(props?.data?.neft_number);
  const [voucherDate, setVoucherDate] = useState(
    props?.data?.voucher_date && props?.data?.voucher_date.split("T")[0]
  );
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userId = sessionStorage.getItem("id")
  // const [ expenditure , setExpenditure ] = useState(props?.data?.expenditure_heads)
  const [partyies] = useState(props?.partyies);
  const [expenditureList] = useState(props?.expenditure);
  let partyObj = {
    value: props?.data?.parties.id,
    label: props?.data?.parties.name,
  };
  const [selectedParty, setSelectedParty] = useState(partyObj);
  let expObj = {
    value: props?.data?.expenditure_heads.id,
    label: props?.data?.expenditure_heads.name,
  };
  const [selectExpenditure, setSelectExpenditure] = useState(expObj)



  const handleSave = async () => {
    setIsLoading(true);
    let payload = {
      partyId: selectedParty?.value,
      particulars: particular,
      amount: amount,
      createdBy,
      approvedAmount: approvedAmount,
      paymentMode: mode,
      voucherDate: voucherDate,
      bankName:bankName,
      userId,
      expenditureHead: selectExpenditure?.value,
    };

    if (neftNumber !== null && neftNumber !== undefined && neftNumber !== "") {
      payload.neftNumber = neftNumber
    }
    if (chequeNumber !== null && chequeNumber !== undefined && chequeNumber !== "") {
      payload.chequeNumber = chequeNumber
    }

    if (tdsDeducted !== null && tdsDeducted !== undefined && tdsDeducted !== "") {
      payload.deductedTds = tdsDeducted
    }
    if (rejectedAmount !== null && rejectedAmount !== undefined && rejectedAmount !== "") {
      payload.rejectedAmount = rejectedAmount
    }

    if (rejectionReason !== null && rejectionReason !== undefined && rejectionReason !== "") {
      payload.rejectedReason = rejectionReason
    }


    if (role === "Admin") {
      payload.voucherNumber = voucher;
    }

    props.hide();

    await authStore.editBankVoucher(
      props.data.voucher_number,
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          notify(response?.data?.meta?.message, "success");
          setIsLoading(false);
          props.onSubmit();
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false);
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false);
      }
    );
  };



  useEffect(() => {
    setIsAdmin(role === "Admin");
  }, []);


  const handleDateChange = (date) => {
    const currentDate = new Date().toISOString().split('T')[0];

    if (date <= new Date(currentDate)) {
      setVoucherDate(date);
    } else {
      notify("Cannot select a future date for the voucher.", "danger");
    }
  };



  const formatDate = (date) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  }


  const openDatepicker1 = () => {
    startCalendarRef.current.setFocus(true);
  };


  const handlePartyNameChange = (event) => {
    const selectedValue = event;
    setSelectedParty(selectedValue);
  };

  const handleMenuOpen = () => {
    setSelectedParty("");
  };
  const handleMenuOpen1 = () => {
    setSelectExpenditure("");
  };

  const handleExpenditureChange = (event) => {
    const selectedValue = event;
    setSelectExpenditure(selectedValue);
  };


  return (
    <Modal {...props} centered className="sucess-content">
      <Modal.Body className="sucess-modal">
        <div className="">
          {isLoading && <Loader />}
          <h4 className="modal-heading">Edit Entries</h4>
          <div className="moodal">
            <div
              className="crossbtn-container"
              onClick={() => props.hide()}
              style={{ textAlign: "right" }}
            >
              <img
                className="crossbutton"
                src={crossbtn}
                alt="..."
                style={{
                  width: "28px",
                  position: "absolute",
                  right: "10px",
                  top: "17px",
                }}
              />
            </div>
          </div>
          <div className="receipt-form-box">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="input-lable">Voucher No.</label>
                <br />
                <input
                  class="form-control"
                  type="text"
                  disabled={role != "Admin"}
                  placeholder="Voucher No."
                  value={voucher}
                  onChange={(e) => setVoucher(e.target.value)}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="input-lable">Voucher Date</label>
                {isAdmin ? (
                  <div className="calendar-container" style={{ position: "relative" }}>
                    <DatePicker
                      className="form-control"
                      selected={voucherDate ? new Date(voucherDate) : null}
                      onChange={handleDateChange}
                      dateFormat="dd-MM-yyyy" // Set the desired date format
                      maxDate={new Date()}
                      ref={startCalendarRef}
                    />
                    <div className="calendar-icon" style={{ position: "absolute", right: "10px", top: "5px" }}>
                      <img
                        src={calendar}
                        alt="tocal"
                        width="20px"
                        onClick={openDatepicker1}
                      />
                    </div>
                  </div>
                ) : (
                  <input
                    className="form-control"
                    readOnly
                    type="text"
                    placeholder="Voucher Date"
                    value={voucherDate ? formatDate(new Date(voucherDate)) : ''}
                    onChange={(e) => setVoucherDate(e.target.value)}
                  />
                )}
              </div>
              <div className="col-md-6 mb-3">
                <label className="input-lable">Name of Party</label>
                <br />
                <Select
                  value={selectedParty?.label}
                  onChange={handlePartyNameChange}
                  options={partyies}
                  isSearchable={true}
                  placeholder={selectedParty?.label}
                  onMenuOpen={handleMenuOpen}
                />
                {/* <input
                  class="form-control"
                  type="text"
                  placeholder="Name of Party"
                  value={nameParty?.name}
                  onChange={(e) => setNameParty(e.target.value)}
                /> */}
              </div>
              <div className="col-md-6 mb-3">
                <label className="input-lable">Particulars</label>
                <br />
                <input
                  class="form-control"
                  type="text"
                  placeholder="Particulars"
                  value={particular}
                  onChange={(e) => {
                    let data = e.target.value.toUpperCase();
                    setParticular(data)
                  }}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="input-lable">Amount</label>
                <br />
                <input
                  class="form-control"
                  type="text"
                  placeholder="Amount  "
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="input-lable">Approved Amount</label>
                <br />
                <input
                  class="form-control"
                  type="text"
                  placeholder="Approved Amount "
                  value={approvedAmount}
                  onChange={(e) => setApprovedAmount(e.target.value)}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="input-lable">Rejected Amount </label>
                <br />
                <input
                  class="form-control"
                  type="text"
                  placeholder="Rejected Amount "
                  value={rejectedAmount}
                  onChange={(e) => setRejectedAmount(e.target.value)}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="input-lable">TDS Deducted</label>
                <br />
                <input
                  class="form-control"
                  type="text"
                  placeholder="TDS Deducted  "
                  value={tdsDeducted}
                  onChange={(e) => setTdsDeducted(e.target.value)}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="input-lable">Reason For Rejection</label>
                <br />
                <input
                  class="form-control"
                  type="text"
                  placeholder="Reason For Rejection  "
                  value={rejectionReason}
                  onChange={(e) => {
                    let data = e.target.value.toUpperCase();
                    setRejectionReason(data)
                  }}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="input-lable">Mode</label>
                <br />
                <input
                  class="form-control"
                  type="text"
                  placeholder="Mode  "
                  value={mode}
                  onChange={(e) => {
                    let data = e.target.value.toUpperCase();
                    setMode(data)
                  }}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="input-lable">Cheque Number</label>
                <br />
                <input
                  class="form-control"
                  type="text"
                  placeholder="Cheque Number  "
                  value={chequeNumber}
                  onChange={(e) => {
                    let data = e.target.value.toUpperCase();
                    setChequeNumber(data)
                  }}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="input-lable">NEFT Number</label>
                <br />
                <input
                  class="form-control"
                  type="text"
                  placeholder="NEFT Number  "
                  value={neftNumber}
                  onChange={(e) => {
                    let data = e.target.value.toUpperCase();
                    setNeftNumber(data)
                  }}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="input-lable">Expenditure Head</label>
                <br />
                <Select
                  value={selectExpenditure?.label}
                  onChange={handleExpenditureChange}
                  options={expenditureList}
                  isSearchable={true}
                  placeholder={selectExpenditure?.label}
                  onMenuOpen={handleMenuOpen1}
                />
              </div>

              <div className="col-md-6 mb-3">
                <label className="input-lable">Bank Name</label>
                <br />
                <input
                  class="form-control"
                  type="text"
                  placeholder="Bank Name "
                  value={bankName}
                  onChange={(e) =>{
                    let data = e.target.value.toUpperCase();
                    setBankName(data)}}
                />
              </div>
            </div>
          </div>
          <div className="text-right mt-6">
            <button className="submit-button text-right" onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditModal;
