import { Store } from "react-notifications-component";

export default function notify( desc = ' ', type = 'success', title = '') {
    switch (type) {
        case 'snack':
            // rootStore.toast.text = title;
            // rootStore.toast.visible = true;
            break;
        case 'remove':
            Store.removeAllNotifications();
            break;
        default:
            Store.removeAllNotifications();
            Store.addNotification({
                title: title,
                message: desc,
                type: type,
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000,
                    onScreen: false,
                    showIcon: true
                }
            });
            break;
    }
};