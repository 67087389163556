import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import "./style.scss";
import notify from "../../shared/notify";
import { useStores } from "../../stores";
import editbtn from "../../assets/images/edit (3).png";
import EditModal from "../../components/Modal/EditUserModal";
import ReactExport from "react-export-excel";
import Loader from "../../helper/Loader/Loader";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const limit = [
  {
    option: 2,
    value: 2,
  },
  {
    option: 10,
    value: 10,
  },
  {
    option: 100,
    value: 100,
  },
  {
    option: 500,
    value: 500,
  },
  {
    option: 1000,
    value: 1000,
  },
];

function User() {
  const [name, setName] = useState();
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();
  const [role, setRole] = useState();
  const [contact, setContact] = useState();
  const [selectedRole, setSelectedRole] = useState("");
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { authStore } = useStores();
  const roleId = sessionStorage.getItem("roleId");
  const [FetchUserdetail, setFetchUserDetail] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const roleid = sessionStorage.getItem("role");
  const [selectedUser, setSelectedUser] = useState();
  const [showModal, setShowModal] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [pageLimit, setPageLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [userData, setUserData] = useState([]);
  // const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  // const isValidEmail = emailRegex.test(email);

  useEffect(() => {
    setIsAdmin(roleid === "Admin");
    HandleRoleData();
    handleFetchUserDetail();
  }, []);

  const HandleRoleData = async () => {
    setIsLoading(true);
    let payload = {
      limit: 10,
      filters: {},
      pageNumber: 1,
    };
    await authStore.getRoleData(
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          setRoles(response?.data?.data?.filteredData?.results);
          setIsLoading(false);
        } else {
          notify(response?.meta?.message, "danger");
          setIsLoading(false);
        }
      },
      (error) => {
        notify(error?.response?.meta?.message, "danger");
        setIsLoading(false);
      }
    );
  };

  const handleRoleNameChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleCreateUserDetail = async () => {
    setIsLoading(true);

    if (/\d/.test(name)) {
      notify("Name should not contain numbers", "danger");
      setIsLoading(false);
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      notify("Invalid email format", "danger");
      setIsLoading(false);
      return;
    }

    if (contact && !/^\d{10}$/.test(contact)) {
      notify("Invalid contact format.", "danger");
      setIsLoading(false);
      return;
    }


    let payload = {
      name: name,
      password: password,
      email: email,
      role_id: roleId,
    };

    if (contact !== "" && contact !== undefined) {
      payload.contact = contact
    }

    await authStore.createUserDetails(
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          setIsLoading(false);
          notify(response?.data?.meta?.message);
          handleFetchUserDetail();
          setName("");
          setPassword("");
          setEmail("");
          setRole("");
          setContact("");
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false);
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false);
      }
    );
  };


  const handleFetchUserDetail = async () => {
    setIsLoading(true);
    let payload = {
      limit: 10,
      filters: {},
      pageNumber: 1,
    };
    callApi(payload);
  };

  const handleEdit = (e, item) => {
    e.preventDefault();
    setSelectedUser(item);
    setShowModal(true);
  };

  const handleFilter = async () => {
    setIsLoading(true);
    let payload = {
      limit: pageLimit,
      filters: {
        name: filterName,
        email: filterEmail,
      },
      pageNumber: page,
    };
    callApi(payload);
  };

  const handleReset = async () => {
    setFilterName("");
    setFilterEmail("");
    setPage(1);
    setPageLimit(10);
    await handleFetchUserDetail();
  };

  const handlePageSubmit = async (index) => {
    setIsLoading(true);
    setPage(index.selected + 1);
    let payload = {
      limit: pageLimit,
      filters: {
        name: filterName,
        email: filterEmail,
      },
      pageNumber: index.selected + 1,
    };
    callApi(payload);
  };

  const limitChangeHandler = async (index) => {
    setIsLoading(true);
    setPageLimit(index);
    let payload = {
      limit: index,
      filters: {
        name: filterName,
        email: filterEmail,
      },
      pageNumber: page,
    };
    callApi(payload);
  };

  const callApi = async (payload) => {
    await authStore.getUsersDetails(
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          setUserData(response?.data?.data);
          setFetchUserDetail(response?.data?.data?.filteredData?.results);
          setIsLoading(false);
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false);
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false);
      }
    );
  };


  return (
    <>
      <div className="leaddetails-container">
        <div className="RightArea">
          <div className="dashboard-area">
            <div className="container-fluid dash-container">
              <div className="needs-validation" noValidate>
                <div className="row">
                  <div className="col-md-12">
                    <div className="hall-header">
                      <h2 className="sub-heading">User Details</h2>
                    </div>
                  </div>
                  <div className="receipt-form-box">
                    <div className="row">
                      <div className="col-md-3">
                        <label className="input-lable">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="rejectionReason"
                          placeholder="Name"
                          value={name?.toUpperCase()}
                          onChange={(e) => {
                            let data = e.target.value.toUpperCase();
                            setName(data)
                          }}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="input-lable">Password</label>
                        <input
                          type="text"
                          className="form-control"
                          id="rejectionReason"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="input-lable">Email<span className="required-field">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          id="rejectionReason"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />

                      </div>
                      <div className="col-md-3">
                        <label className="input-lable">Contact</label>
                        <input
                          type="text"
                          className="form-control"
                          id="rejectionReason"
                          placeholder="Contact"
                          value={contact}
                          maxLength={10}
                          onChange={(e) => setContact(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="input-lable">Role</label>
                        <select
                          className="form-control"
                          id="partyName"
                          value={selectedRole}
                          onChange={handleRoleNameChange}
                        >
                          <option>select</option>
                          {roles.map((role, index) => (
                            <option key={index} value={role.value}>
                              {role.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12" style={{ textAlign: "right" }}>
                    <button
                      className="submit-button"
                      type="submit"
                      onClick={handleCreateUserDetail}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
              <div className="needs-validation">
                <div className="row ">
                  <div className="col-md-12">
                    <div className="hall-header">
                      <h2 className="sub-heading">Entries</h2>
                    </div>
                  </div>
                  <div className="col-md-12 filter-container">
                    <div>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter User Name"
                        value={filterName?.toUpperCase()}
                        onChange={(e) => setFilterName(e.target.value)}
                      />
                    </div>
                    <div>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter Email"
                        value={filterEmail?.toUpperCase()}
                        onChange={(e) => setFilterEmail(e.target.value)}
                      />
                    </div>

                    <div className="btn-container">
                      <button
                        className="submit-button"
                        type="submit"
                        onClick={handleFilter}
                      >
                        Filter
                      </button>
                      <button
                        className="submit-button"
                        type="submit"
                        onClick={handleReset}
                      >
                        Reset
                      </button>
                      {FetchUserdetail.length > 0 &&
                        <button className="submit-button">
                          <ExcelFile element={<span style={{ cursor: "pointer" }}  > Export  </span>} >
                            <ExcelSheet
                              data={FetchUserdetail?.map((item, i) => ({
                                ...item,
                                index: i + 1,
                              }))}
                              name="Sheet"
                            >
                              <ExcelColumn label="Sr.No" value="index" />
                              <ExcelColumn label="Name " value="name" />
                              <ExcelColumn label="Email" value="email" />
                              <ExcelColumn label="Contact" value="contact" />
                              <ExcelColumn
                                label="Role"
                                value={(col) => col.roles?.name}
                              />
                            </ExcelSheet>
                          </ExcelFile>
                        </button>}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div>
                      <label className="d-flex align-items-center p-0 col-md-2">
                        {page === 1 ? (
                          <select
                            value={pageLimit}
                            className="form-control selectbox-container mr-2"
                            onChange={(e) => limitChangeHandler(e.target.value)}
                          >
                            {limit.length > 0
                              ? limit.map((item, index) => (
                                <option key={index} value={item.option}>
                                  {item.value}
                                </option>
                              ))
                              : null}
                          </select>
                        ) : (
                          <p className="form-control selectbox-container mx-2 my-0">
                            {pageLimit}
                          </p>
                        )}
                        <span> entries</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12 listing-table">
                    <div className="data-table  py-4 table-responsive">
                      <table className="details-box table-bordered  ">
                        <thead className="thead-box">
                          <tr className="thead">
                            <th className="action">Sr.No</th>
                            <th className="action">Name</th>
                            <th className="action">Email</th>
                            <th className="action">Contact</th>
                            <th className="action">Role</th>
                            <th className="action">Action</th>
                          </tr>
                        </thead>
                        {FetchUserdetail.length > 0 ? (
                          <tbody>
                            {FetchUserdetail.map((item, i) => {
                              return (
                                <>
                                  <tr key={item?.contact}>
                                    <td className="table-td">{i + 1}</td>
                                    <td className="table-td"> {item.name}</td>
                                    <td className="table-td" style={{textTransform:"none"}}> {item.email}</td>
                                    <td className="table-td">{item.contact}</td>
                                    <td className="table-td">
                                      {item.roles?.name}
                                    </td>
                                    <td className="table-td">
                                      <button className="disablebtn">
                                        <img
                                          className="deletebutton"
                                          src={editbtn}
                                          alt="..."
                                          onClick={(e) => handleEdit(e, item)}
                                        />
                                      </button>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td className="text-center p-3" colSpan={6}>
                                No Records found...
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="d-flex align-items-center justify-content-between my-4">
                      <div>
                        <p>
                          showing {userData.filteredCount} out of{" "}
                          {userData.totalRecords}
                        </p>
                      </div>
                      <div>
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={userData.totalPages}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageSubmit}
                          containerClassName={
                            "pagination justify-content-center"
                          }
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditModal
        show={showModal}
        hide={() => {
          setShowModal(false);
        }}
        key={selectedUser?.name}
        data={selectedUser}
        onSubmit={handleFetchUserDetail}
      />
      {isLoading && <Loader />}
    </>
  );
}

export default User;
