import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import "./modal.scss";
import crossbtn from "../../assets/images/delete-button.png";
import { useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.jpg"

const PDFGenerator = (props) => {
  const location = useLocation();
  const url = location.pathname.split("/")[2];

  const MyPDF = ({ data }) => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View  style={{borderWidth: 2, borderColor: '#000', borderStyleL: 'solid' }}>
          <View style={{justifyContent:"center",  width:"100%" , alignItems:"center" , borderBottom:2,}}>
            <View style={{ width :"160" , marginBottom:"-10px" , paddingLeft:"5" , paddingRight:"5" , backgroundColor:"white"}}>
            <Image  src={logo} style={{ width :"100%"}} /> 
          </View>
          </View>
          <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            paddingVertical: 10,
          }}
        >
          {url === "cashvoucher" ? ( <Text style={{ textAlign: "center" , textDecoration:"underline"}}> CASH VOUCHER </Text> ) : (  "" )}
          {url === "bankvoucher" ? ( <Text style={{ textAlign: "center", textDecoration:"underline" }}>BANK VOUCHER</Text> ) : (  "" )}
          {url === "receiptvoucher" ? (<Text style={{ textAlign: "center" , textDecoration:"underline"}}>RECEIPT VOUCHER</Text>) : ( "" )}
        </View> 
        <View style={{borderTopWidth: 2, borderColor: '#000', borderStyleL: 'solid', padding: 12,}}>
          {Object.entries(data).map(([label, value], index) => (
            <View key={index} style={styles.row}>
                <Text style={[styles.label, { width: 150, marginRight: 10, textTransform:"uppercase" }]}>{label} :</Text>
              <Text style={[styles.value,{ textTransform:"uppercase"}]}>{value}</Text>
            </View>
          ))}
        </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <Modal {...props} centered className="sucess-content">
      <Modal.Body className="sucess-modal">
        <div className="">
          <h4 className="modal-heading pb-3">Download Pdf</h4>
          <div
            className="crossbtn-container"
            onClick={() => props.hide()}
            style={{ textAlign: "right" }}
          >
            <img
              className="crossbutton"
              src={crossbtn}
              alt="..."
              style={{
                width: "28px",
                position: "absolute",
                right: "10px",
                top: "17px",
              }}
            />
          </div>
          <PDFViewer width="100%" height="500">
            <MyPDF data={props.data} />
          </PDFViewer>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PDFGenerator;

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    flexWrap: "wrap",
    padding: 20,
  },
  row: {
    flexDirection: "row",
    width: "100%",
    marginBottom: 10,
  },
  label: {
    fontWeight: "bold",
    fontSize: 10,
    width: "40%",
  },
  value: {
    fontSize: 10,
    width: "70%",
  },
});
