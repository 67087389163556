import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import "./modal.scss";
import crossbtn from "../../assets/images/delete-button.png";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import Loader from "../../helper/Loader/Loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendar from "../../assets/images/calendar.png";

const EditModal = (props) => {
  const startCalendarRef = useRef();
  const startCalendarRefParty = useRef();
  const { authStore } = useStores();
  const role = sessionStorage.getItem("role");
  const createdd = sessionStorage.getItem("id");
  const [isAdmin, setIsAdmin] = useState(false);
  const [voucher, setVoucher] = useState(props?.data?.voucher_number);
  const [banquetNumber, setBanquetNumber] = useState(
    props?.data?.banquet_number
  );
  const [expectedPax, setExpectedPax] = useState(props?.data?.pax);
  const [particular, setParticular] = useState(props?.data?.particulars);
  const [cashAmount, setCashAmount] = useState(props?.data?.cash_amount);
  const [cardAmount, setCardAmount] = useState(props?.data?.card_amount);
  const [bankAmount, setBankAmount] = useState(props?.data?.bank_amount);
  const [totalAmount, setTotalAmount] = useState(props?.data?.total_amount);
  const [transactionId, setTransactionId] = useState(props?.data?.transaction_id);
  const [voucherDate, setVoucherDate] = useState(
    props?.data?.voucher_date && props?.data?.voucher_date.split("T")[0]
  );
  const [partyName, setPartyName] = useState(props?.data?.parties);
  const [partyDate, setPartyDate] = useState(
    props?.data?.party_date && props?.data?.party_date.split("T")[0]
  );
  const [isLoading, setIsLoading] = useState(false);
  let hallObj = {
    value: props?.data?.halls.id,
    label: props?.data?.halls.name,
  }
  const [selectedHall, setSelectedHall] = useState(hallObj);
  const [guestNum, setGuestNum] = useState(props?.data?.guest_contact);
  const userId = sessionStorage.getItem("id");
  const [partyies] = useState(props?.partyies);
  let partyObj = {
    value: props?.data?.parties.id,
    label: props?.data?.parties.name,
  };
  const [selectedParty, setSelectedParty] = useState(partyObj);
  const [hallDetail] = useState(props?.hallDetail);
  const handleSave = async () => {
    setIsLoading(true);
    let payload = {
      partyId: selectedParty.value,
      particulars: particular,
      cashAmount: cashAmount,
      createdBy: createdd,
      cardAmount: cardAmount,
      bankAmount: bankAmount,
      totalAmount: totalAmount,
      partyDate: partyDate,
      hallId: selectedHall?.value,
      voucherDate: voucherDate,
      transactionId: transactionId !== "" && transactionId !== undefined && transactionId !== null ? transactionId : undefined,
      userId,
    };

    if (
      expectedPax !== null &&
      expectedPax !== undefined &&
      expectedPax !== ""
    ) {
      payload.pax = expectedPax;
    }

    if (
      banquetNumber !== null &&
      banquetNumber !== undefined &&
      banquetNumber !== ""
    ) {
      payload.banquetNumber = banquetNumber;
    }
    if (guestNum !== null && guestNum !== undefined && guestNum !== "") {
      payload.guestContact = guestNum;
    }

    if (role === "Admin") {
      payload.voucherNumber = voucher;
    }
    props.hide();
    await authStore.editRecieptVoucher(
      props.data.voucher_number,
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          notify(response?.data?.meta?.message, "success");
          setIsLoading(false);
          props.onSubmit();
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false);
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    setIsAdmin(role === "Admin");
  }, [role]);

  const handleHallNameChange = (event) => {
    setSelectedHall(event);
  };

  const handleDateChange = (date) => {
    const currentDate = new Date().toISOString().split("T")[0];
    if (date <= new Date(currentDate)) {
      setVoucherDate(date);
    } else {
      notify("Cannot select a future date for the voucher.", "danger");
    }
  };
  const handleDateChangeParty = (date) => {
    setPartyDate(date);
  };

  const formatDate = (date) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  const openDatepicker1 = () => {
    startCalendarRef.current.setFocus(true);
  };
  const openDatepicker2 = () => {
    startCalendarRefParty.current.setFocus(true);
  };

  const handlePartyNameChange = (event) => {
    const selectedValue = event;
    setSelectedParty(selectedValue);
  };

  const handleMenuOpen = () => {
    setSelectedParty("");
  };

  const handleHallMenuOpen = () => {
    setSelectedHall("");
  };

  const updateTotalAmount = () => {
    const cash = parseFloat(cashAmount) || 0;
    const card = parseFloat(cardAmount) || 0;
    const bank = parseFloat(bankAmount) || 0;
    const total = cash + card + bank;
    setTotalAmount(total.toFixed(2)); // You can adjust the decimal places as needed
  };

  return (
    <Modal {...props} centered className="sucess-content">
      <Modal.Body className="sucess-modal">
        <div className="">
          <h4 className="modal-heading">Edit Entries</h4>
          <div className="moodal">
            <div
              className="crossbtn-container"
              onClick={() => props.hide()}
              style={{ textAlign: "right" }}
            >
              <img
                className="crossbutton"
                src={crossbtn}
                alt="..."
                style={{
                  width: "28px",
                  position: "absolute",
                  right: "10px",
                  top: "17px",
                }}
              />
            </div>
          </div>
          <div className="receipt-form-box">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="input-lable">Voucher No.</label>
                <br />
                <input
                  class="form-control"
                  type="text"
                  disabled={role !== "Admin"}
                  placeholder="Voucher No."
                  value={voucher}
                  onChange={(e) => setVoucher(e.target.value)}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="input-lable">Voucher Date</label>
                {isAdmin ? (
                  <div
                    className="calendar-container"
                    style={{ position: "relative" }}
                  >
                    <DatePicker
                      className="form-control"
                      selected={voucherDate ? new Date(voucherDate) : null}
                      onChange={handleDateChange}
                      maxDate={new Date()}
                      dateFormat="dd-MM-yyyy" // Set the desired date format
                      ref={startCalendarRef}
                    />
                    <div
                      className="calendar-icon"
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "5px",
                      }}
                    >
                      <img
                        src={calendar}
                        alt="tocal"
                        width="20px"
                        onClick={openDatepicker1}
                      />
                    </div>
                  </div>
                ) : (
                  <input
                    className="form-control"
                    readOnly
                    type="text"
                    placeholder="Voucher Date"
                    value={voucherDate ? formatDate(new Date(voucherDate)) : ""}
                    onChange={(e) => setVoucherDate(e.target.value)}
                  />
                )}
              </div>
              <div className="col-md-6 mb-3">
                <label className="input-lable">Name of Party</label>
                <br />
                <Select
                  value={selectedParty?.label}
                  onChange={handlePartyNameChange}
                  options={partyies}
                  isSearchable={true}
                  placeholder={selectedParty?.label}
                  onMenuOpen={handleMenuOpen}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="input-lable">Party Date</label>
                <div
                  className="calendar-container"
                  style={{ position: "relative" }}
                >
                  <DatePicker
                    className="form-control"
                    selected={partyDate ? new Date(partyDate) : null}
                    onChange={handleDateChangeParty}
                    dateFormat="dd-MM-yyyy" // Set the desired date format
                    ref={startCalendarRefParty}
                  />
                  <div
                    className="calendar-icon"
                    style={{ position: "absolute", right: "10px", top: "5px" }}
                  >
                    <img
                      src={calendar}
                      alt="tocal"
                      width="20px"
                      onClick={openDatepicker2}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6 mb-3">
                <label className="input-lable">Particulars</label>
                <br />
                <input
                  class="form-control"
                  type="text"
                  placeholder="Particulars"
                  value={particular}
                  onChange={(e) => {
                    let data = e.target.value.toUpperCase();
                    setParticular(data)
                  }}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="input-lable">Bank Amount</label>
                <br />
                <input
                  class="form-control"
                  type="text"
                  placeholder="Bank Amount  "
                  value={bankAmount}
                  onChange={(e) => setBankAmount(e.target.value)}
                  onBlur={updateTotalAmount}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="input-lable">Card Amount</label>
                <br />
                <input
                  class="form-control"
                  type="text"
                  placeholder="Card Amount  "
                  value={cardAmount}
                  onChange={(e) => setCardAmount(e.target.value)}
                  onBlur={updateTotalAmount}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="input-lable">Cash Amount</label>
                <br />
                <input
                  class="form-control"
                  type="text"
                  placeholder="Cash Amount  "
                  value={cashAmount}
                  onChange={(e) => setCashAmount(e.target.value)}
                  onBlur={updateTotalAmount}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="input-lable">Total Amount</label>
                <br />
                <input
                  class="form-control"
                  type="text"
                  readOnly
                  placeholder="Total Amount  "
                  value={totalAmount}
                  onChange={(e) => setTotalAmount(e.target.value)}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="input-lable">Expected Pax</label>
                <br />
                <input
                  class="form-control"
                  type="text"
                  placeholder="Expected Pax  "
                  value={expectedPax}
                  onChange={(e) => setExpectedPax(e.target.value)}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="input-lable">Banquet Number</label>
                <br />
                <input
                  class="form-control"
                  type="text"
                  placeholder="Banquet Number  "
                  value={banquetNumber}
                  onChange={(e) => setBanquetNumber(e.target.value)}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="input-lable">Guest Number</label>
                <br />
                <input
                  class="form-control"
                  type="text"
                  maxLength={10}
                  placeholder="Guest Number  "
                  value={guestNum}
                  onChange={(e) => setGuestNum(e.target.value)}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="input-lable" for="partyName">
                  Hall<span className="required-field">*</span>
                </label>
                <Select
                  value={selectedHall?.label}
                  onChange={handleHallNameChange}
                  options={hallDetail}
                  isSearchable={true}
                  style={{}}
                  placeholder={selectedHall?.label}
                  onMenuOpen={handleHallMenuOpen}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="input-lable">Transaction ID</label>
                <br />
                <input
                  class="form-control"
                  type="text"
                  placeholder="Transaction ID"
                  value={transactionId}
                  onChange={(e) => {
                    let data = e.target.value.toUpperCase();
                    setTransactionId(data)
                  }}
                />
              </div>
            </div>
          </div>
          <div className="text-right mt-3">
            <button className="submit-button text-right" onClick={handleSave}>
              Save
            </button>
          </div>
          {isLoading && <Loader />}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditModal;
