import React, { useEffect, useState, useRef } from "react";
import "./style.scss";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import { useStores } from "../../stores";
import Loader from "../../helper/Loader/Loader";
import notify from "../../shared/notify";
import ReactExport from "react-export-excel";
import moment from "moment";
import calendar from "../../assets/images/calendar.png";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const limit = [
  {
    option: 2,
    value: 2,
  },
  {
    option: 10,
    value: 10,
  },
  {
    option: 100,
    value: 100,
  },
  {
    option: 500,
    value: 500,
  },
  {
    option: 1000,
    value: 1000,
  },
];

const DeletedVoucher = () => {
  const startCalendarRef = useRef();
  const endCalendarRef = useRef();
  const { authStore } = useStores();
  const [pageLimit, setPageLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [filterVoucherNumber, setFilterVoucherNumber] = useState("");
  const [filterparty, setFilterParty] = useState("");
  const [filteramount, setFilterAmount] = useState("");
  const [cashVoucherData, setCashVoucherData] = useState([]);

  useEffect(() => {
    getTrackData();
  }, []);

  const getTrackData = async () => {
    setIsLoading(true);
    let payload = {
      limit: pageLimit,
      filters: {},
      pageNumber: page,
    };
    callApi(payload);
  };

  const handleStartDate = (date) => {
    setStartDate(date);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
  };

  const openDatepicker1 = () => {
    startCalendarRef.current.setFocus(true);
  };

  const openDatepicker2 = () => {
    endCalendarRef.current.setFocus(true);
  };

  const handleFilter = async () => {
    let newStartDate = startDate
      ? moment(new Date(startDate)).format("YYYY-MM-DD")
      : null;
    let newEndDate = endDate
      ? moment(new Date(endDate)).format("YYYY-MM-DD")
      : null;
    let payload = {
      limit: pageLimit,
      filters: {
        voucherNumber: filterVoucherNumber,
        voucherFromDate: newStartDate,
        voucherToDate: newEndDate,
        partyName: filterparty,
        amount: filteramount,
      },
      pageNumber: page,
    };
    callApi(payload);
  };

  const handleReset = async () => {
    setFilterAmount("");
    setFilterParty("");
    setFilterVoucherNumber("");
    setPage(1);
    setPageLimit(10);
    startCalendarRef.current.value = "";
    endCalendarRef.current.value = "";
    setStartDate(null);
    setEndDate(null);
    await getTrackData();
  };

  const limitChangeHandler = async (index) => {
    setPageLimit(index);
    let newStartDate = startDate
      ? moment(new Date(startDate)).format("YYYY-MM-DD")
      : null;
    let newEndDate = endDate
      ? moment(new Date(endDate)).format("YYYY-MM-DD")
      : null;
    let payload = {
      limit: index,
      filters: {
        voucherNumber: filterVoucherNumber,
        voucherFromDate: newStartDate,
        voucherToDate: newEndDate,
        partyName: filterparty,
        amount: filteramount,
      },
      pageNumber: page,
    };
    callApi(payload);
  };

  const callApi = async (payload) => {
    await authStore.trackCashVoucher(
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          setCashVoucherData(response?.data?.data);
          setFetchedData(response?.data?.data?.filteredData?.results);
          setIsLoading(false);
        } else {
          notify(response?.meta?.message);
          setIsLoading(false);
        }
      },
      (error) => {
        notify(error?.response?.meta?.message);
      }
    );
  };

  const handlePageSubmit = async (index) => {
    setPage(index.selected + 1);
    let newStartDate = startDate
      ? moment(new Date(startDate)).format("YYYY-MM-DD")
      : null;
    let newEndDate = endDate
      ? moment(new Date(endDate)).format("YYYY-MM-DD")
      : null;
    let payload = {
      limit: pageLimit,
      filters: {
        voucherNumber: filterVoucherNumber,
        voucherFromDate: newStartDate,
        voucherToDate: newEndDate,
        partyName: filterparty,
        amount: filteramount,
      },
      pageNumber: index.selected + 1,
    };
    callApi(payload);
  };

  return (
    <div className="leaddetails-container">
      <div className="RightArea">
        <div className="dashboard-area">
          <div className="container-fluid dash-container">
            <div className="needs-validation">
              <div className="hall-header">
                <h2 className="sub-heading sub-mob-view">Deleted Cash Voucher</h2>
              </div>
              <div className="col-md-12 filter-container">
                <div className="calendar-container">
                  <DatePicker
                    className="form-control"
                    placeholderText="From Date"
                    selected={startDate}
                    onChange={(date) => {
                      handleStartDate(date);
                    }} //only when value has changed
                    dateFormat="dd-MM-yyyy"
                    ref={startCalendarRef}
                  />
                  <div className="calendar-icon">
                    <img
                      src={calendar}
                      alt="fromcal"
                      width="20px"
                      onClick={openDatepicker1}
                    />
                  </div>
                </div>
                <div className="calendar-container">
                  <DatePicker
                    className="form-control"
                    placeholderText="To Date"
                    selected={endDate}
                    onChange={(date) => {
                      handleEndDate(date);
                    }} //only when value has changed
                    dateFormat="dd-MM-yyyy"
                    ref={endCalendarRef}
                  />
                  <div className="calendar-icon">
                    <img
                      src={calendar}
                      alt="tocal"
                      width="20px"
                      onClick={openDatepicker2}
                    />
                  </div>
                </div>
                <div>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Voucher Number"
                    value={filterVoucherNumber}
                    onChange={(e) => setFilterVoucherNumber(e.target.value)}
                  />
                </div>
                <div>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Party Name"
                    value={filterparty?.toUpperCase()}
                    onChange={(e) => setFilterParty(e.target.value)}
                  />
                </div>
                <div>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Amount"
                    value={filteramount}
                    onChange={(e) => setFilterAmount(e.target.value)}
                  />
                </div>
                <div className="btn-container">
                  <button
                    className="submit-button"
                    type="submit"
                    onClick={handleFilter}
                  >
                    Filter
                  </button>
                  <button
                    className="submit-button"
                    type="submit"
                    onClick={handleReset}
                  >
                    Reset
                  </button>
                  <button className="submit-button">
                  <ExcelFile  element={  <span style={{ cursor: "pointer" }}  > Export  </span>  } >
                      <ExcelSheet
                        data={fetchedData.map((item, i) => ({
                          ...item,
                          index: i + 1,
                          voucher_date: new Date(
                            item.voucher_date
                          ).toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "2-digit",
                          }), updated_at: new Date(
                            item.updated_at
                          ).toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "2-digit",
                          }),
                        }))}
                        name="Sheet"
                      >
                        <ExcelColumn label="Sr.No" value="index" />
                        <ExcelColumn
                          label="Voucher No."
                          value="voucher_number"
                        />
                        <ExcelColumn
                          label="Voucher Date"
                          value="voucher_date"
                        />
                        <ExcelColumn label="Name of Party" value= {(item) => item?.parties?.name?.toUpperCase()}/>
                        <ExcelColumn label="Expenditure Head" value={(item) => item?.expenditure_heads?.name?.toUpperCase()} />
                        <ExcelColumn label="Particulars"  value={(item) => item?.particulars?.toUpperCase()} />
                        <ExcelColumn label="Amount" value="amount" />
                        <ExcelColumn label="Deleted Date" value={(item) => item?.updated_at} />
                      </ExcelSheet>
                    </ExcelFile>
                  </button>
                </div>
              </div>
              <div className="col-md-12">
                <div>
                  <label className="d-flex align-items-center p-0 col-md-2">
                    {page === 1 ? (
                      <select
                        value={pageLimit}
                        className="form-control selectbox-container mr-2"
                        onChange={(e) => limitChangeHandler(e.target.value)}
                      >
                        {limit.length > 0
                          ? limit.map((item, index) => (
                              <option key={index} value={item.option}>
                                {item.value}
                              </option>
                            ))
                          : null}
                      </select>
                    ) : (
                      <p className="form-control selectbox-container mx-2 my-0">
                        {pageLimit}
                      </p>
                    )}
                    <span> entries</span>
                  </label>
                </div>
              </div>
              <div className="col-md-12 listing-table">
                <div className="data-table  py-4 table-responsive">
                  <table
                    className="details-box table-bordered "
                    id="table-to-xls"
                  >
                    <thead className="thead-box">
                      <tr className="thead">
                        <th className="action">Sr.No</th>
                        <th className="action">Voucher No.</th>
                        <th className="action">Voucher Date</th>
                        <th className="name">Name of Party</th>
                        <th className="name">Particulars</th>
                        <th className="name">Amount</th>
                        <th className="name">Expenditure Head</th>
                        <th className="name">Deleted Date</th>
                      </tr>
                    </thead>
                    {fetchedData.length > 0 ? (
                      <tbody>
                        {fetchedData.map((item, i) => {
                          return (
                            <>
                              <tr key={item.voucher_number}>
                                <td className="table-td">{i + 1}</td>
                                <td className="table-td">
                                  {item.voucher_number}
                                </td>
                                <td className="table-td">
                                  {item.voucher_date &&
                                    new Date(
                                      item.voucher_date
                                    ).toLocaleDateString("en-GB")}
                                </td>
                                <td className="table-td">{item.parties?.name}</td>
                                <td className="table-td">{item.particulars}</td>
                                <td className="table-td">{item.amount}</td>
                                <td className="table-td">{item?.expenditure_heads?.name}</td>
                                <td className="table-td">
                                  {item?.updated_at &&
                                    new Date(
                                      item?.updated_at
                                    ).toLocaleDateString("en-GB")}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td className="text-center p-3" colSpan={8}>
                            No Records found...
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
              <div className="col-md-12">
                <div className="d-flex align-items-center justify-content-between my-4">
                  <div>
                    <p>
                      showing {cashVoucherData.filteredCount} out of{" "}
                      {cashVoucherData.totalRecords}
                    </p>
                  </div>
                  <div>
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      pageCount={cashVoucherData.totalPages}
                      marginPagesDisplayed={3}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageSubmit}
                      containerClassName={"pagination justify-content-center"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
    </div>
  );
};

export default DeletedVoucher;
