import React, { useState, useEffect } from "react";
import "./style.scss";
import { Link, useLocation } from "react-router-dom";
import cashVoucher from "../../../src/assets/images/voucher.png";
import bankVoucher from "../../../src/assets/images/payment-terminal.png";
import receiptvoucher from "../../../src/assets/images/receipt.png";
import hallvoucher from "../../../src/assets/images/group.png";
import refundvoucher from "../../../src/assets/images/cash-back.png";
import dropodownIcon from "../../assets/images/down.png";
import TrackImg from "../../assets/images/delete.png";
import $ from "jquery";
import ExpenditureIcon from "../../assets/images/expenses.png";

const Sidebar = () => {
  const [showCashRecord, setShowCashRecord] = useState(false);
  const [showBankRecord, setShowBankRecord] = useState(false);
  const [showRecieptRecord, setShowRecieptRecord] = useState(false);
  const MenuHide = () => {
    $(".wrapper").removeClass("active");
  };

  const role = sessionStorage.getItem("role");
  const [showRole, setShowRole] = useState(false);
  useEffect(() => {
    setShowRole(role === "Admin");
  });

  const location = useLocation();
  const url = location?.pathname.split("/")[2];

  return (
    <div className="Sidebar-View">
      <div className="BodySection">
        <div className="wrapper">
          <nav id="sidebar" className="sidebar-container">
            <div className="text-hover">
              <Link
                className="decoration-none"
                to={`/cashvoucher`}
                onClick={() => {
                  sessionStorage.removeItem("applicationData");
                  sessionStorage.removeItem("appType");
                }}
              >
                <div
                  onClick={() => setShowCashRecord(!showCashRecord)}
                  className={"dropdown nav-container "}
                >
                  <img className="nav-icon" src={cashVoucher} />
                  <div className="nav-link-container">
                    <button className="nav-link" type="button">
                      Cash Voucher
                    </button>
                    <img className="dropdown-icon" src={dropodownIcon} />
                  </div>
                </div>
              </Link>
            </div>
            {showCashRecord && (
              <div className="text-hover">
                <div className="dropDown-menu">
                  <Link
                    className="decoration-none"
                    to={`/trackcashvoucher`}
                  >
                    <div
                      onClick={MenuHide()}
                      className={
                        "nav-link-container  menu-hide" +
                        (url === "trackrcashvoucher" ? "active" : "")
                      }
                    >
                      <div className="track-img">
                        <img className="nav-icon" src={TrackImg} />
                        <button className="nav-link" type="button">
                          Deleted Voucher
                        </button>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            )}
            <div className="text-hover">
              <Link
                className="decoration-none"
                to={`/bankvoucher`}
                onClick={() => {
                  sessionStorage.removeItem("applicationData");
                  sessionStorage.removeItem("appType");
                }}
              >
                <div
                  onClick={() => setShowBankRecord(!showBankRecord)}
                  className={"dropdown nav-container "}
                >
                  <img className="nav-icon" src={bankVoucher} />
                  <div className="nav-link-container">
                    <button className="nav-link" type="button">
                      Bank Voucher
                    </button>
                    <img className="dropdown-icon" src={dropodownIcon} />
                  </div>
                </div>
              </Link>
            </div>
            {showBankRecord && (
              <div className="text-hover">
                <div className="dropDown-menu">
                  <Link
                    className="decoration-none"
                    to={`/trackbankvoucher`}
                  >
                    <div
                      onClick={MenuHide()}
                      className={
                        "nav-link-container menu-hide" +
                        (url === "trackbankvoucher" ? "active" : "")
                      }
                    >
                      <div className="track-img">
                        <img className="nav-icon" src={TrackImg} />
                        <button className="nav-link" type="button">
                          Deleted Voucher
                        </button>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            )}
            <div className="text-hover">
              <Link
                className="decoration-none"
                to={`/receiptvoucher`}
                onClick={() => {
                  sessionStorage.removeItem("applicationData");
                  sessionStorage.removeItem("appType");
                }}
              >
                <div
                  onClick={() => setShowRecieptRecord(!showRecieptRecord)}
                  className={"dropdown nav-container "}
                >
                  <img className="nav-icon" src={receiptvoucher} />
                  <div className="nav-link-container">
                    <button className="nav-link" type="button">
                      Receipt Voucher
                    </button>
                    <img className="dropdown-icon" src={dropodownIcon} />
                  </div>
                </div>
              </Link>
            </div>
            {showRecieptRecord && (
              <div className="text-hover">
                <div className="dropDown-menu">
                  <Link
                    className="decoration-none"
                    to={`/trackreceiptvoucher`}
                  >
                    {" "}
                    <div
                      onClick={MenuHide()}
                      className={
                        "nav-link-container  menu-hide" +
                        (url === "trackreceiptvoucher" ? "active" : "")
                      }
                    >
                      <div className="track-img">
                        <img className="nav-icon" src={TrackImg} />
                        <button className="nav-link" type="button">
                          Deleted Voucher
                        </button>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            )}
            {/* <Link
              className="decoration-none"
              to={`/refundvoucher`}
              onClick={() => {
                sessionStorage.removeItem("applicationData");
                sessionStorage.removeItem("appType");
              }}
            >
              <div onClick={MenuHide} className={"dropdown nav-container "}>
                <img className="nav-icon" src={refundvoucher} />
                <div className="nav-link-container">
                  <button className="nav-link" type="button">
                    Refund Voucher
                  </button>
                </div>
              </div>
            </Link> */}
            <div className="text-hover">
              <Link
                className="decoration-none"
                to={`/hall`}
                onClick={() => {
                  sessionStorage.removeItem("applicationData");
                  sessionStorage.removeItem("appType");
                }}
              >
                <div onClick={MenuHide} className={"dropdown nav-container "}>
                  <img className="nav-icon" src={hallvoucher} />
                  <div className="nav-link-container">
                    <button className="nav-link" type="button">
                      Hall
                    </button>
                  </div>
                </div>
              </Link>
            </div>
            <div className="text-hover">
              <Link
                className="decoration-none"
                to={`/party`}
                onClick={() => {
                  sessionStorage.removeItem("applicationData");
                  sessionStorage.removeItem("appType");
                }}
              >
                <div onClick={MenuHide} className={"dropdown nav-container "}>
                  <img className="nav-icon" src={hallvoucher} />
                  <div className="nav-link-container">
                    <button className="nav-link" type="button">
                      Party
                    </button>
                  </div>
                </div>
              </Link>
            </div>
            {showRole && (
              <div className="text-hover">
                <Link
                  className="decoration-none"
                  to={`/users`}
                  onClick={() => {
                    sessionStorage.removeItem("applicationData");
                    sessionStorage.removeItem("appType");
                  }}
                >
                  <div onClick={MenuHide} className={"dropdown nav-container "}>
                    <img className="nav-icon" src={hallvoucher} />
                    <div className="nav-link-container">
                      <button className="nav-link" type="button">
                        Users
                      </button>
                    </div>
                  </div>
                </Link>
              </div>
            )}
            <div className="text-hover">
              <Link
                className="decoration-none"
                to={`/expenditure`}
                onClick={() => {
                  sessionStorage.removeItem("applicationData");
                  sessionStorage.removeItem("appType");
                }}
              >
                <div onClick={MenuHide} className={"dropdown nav-container "}>
                  <img className="nav-icon" src={ExpenditureIcon} />
                  <div className="nav-link-container">
                    <button className="nav-link" type="button">
                      Expenditure
                    </button>
                  </div>
                </div>
              </Link>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};
export default Sidebar;
