import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../index.css";
import "./style.scss";
import $ from "jquery";
import mobilemenu from "../../../src/assets/images/menu.png";
import mobilemenuSecond from "../../../src/assets/images/mobmenu.png";
import dropodownIcon from "../../../src/assets/images/arrow-down-sign-to-navigate.png";
import User from "../../../src/assets/images/user (4).png";
import EditModal from "../../components/Modal/EditPasswordModal";
import logo from "../../assets/images/logo1.png"


const Header = (props) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false)
  const [show, setShow] = useState()
  const handleLogOut = async () => {
    sessionStorage.removeItem("isAuth");
    sessionStorage.removeItem("userName");
    sessionStorage.removeItem("userName");
    sessionStorage.removeItem("Role");
    sessionStorage.removeItem("token");
    clearCacheData();
  };

  const clearCacheData = () => {
    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    navigate(`/`);
    window.location.reload();
  };

  const user = sessionStorage.getItem("name");

  const [showApplicationType, setShowApplicationType] = useState(false);

  const MenuClick = () => {
    $(".wrapper").toggleClass("active");
    setShow(!show)
  };

  const handleUserChangePassword = () => {
    setShowModal(true)
  };

  const handleOnClose = () => {
    setShowModal(false)
  }

  return (
    <header className="HeaderSection">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-6 col-6 text-left d-flex">
            <div className="mr-2">
              <div className="mobile-menu" onClick={MenuClick}>
                {show ? (<img
                  src={mobilemenuSecond}
                  style={{ width: "14px" }}
                  className="img-fluid mobile-memu-img"
                  alt="mobile menu"
                />) : (<img
                  src={mobilemenu}
                  className="img-fluid mobile-memu-img"
                  alt="mobile menu"
                />)}

              </div>
            </div>
            <div className="logo-img">
              <img src={logo} className="img-fluid logo" alt="Logo" />
            </div>
          </div>
          <div className="col-md-6 col-6 text-right ml-auto pr-md-4">
            <div className="dropdown-container">
              <div className={"dropdown nav-container "}>
                <div
                  className="nav-link-container flex-box logout-btn"
                  onClick={() => setShowApplicationType(!showApplicationType)}
                >
                  <div className="flex-box">
                    <img className="user-icon mr-1" src={User} alt="" />
                    <p className="userid" style={{textTransform:"lowercase"}}>{user}</p>
                  </div>
                  <img className="dropdown-icon" src={dropodownIcon} alt="" />
                </div>
                {showApplicationType && (
                  <div className="dropDown-menu">
                    <div className=" dropDown-menu-item">
                      <div className="change-password" onClick={(e) => handleUserChangePassword(e)} >Change Password</div>
                      <button
                        className="flex-box dropDown-menu-item p-0"
                        type="button"
                        onClick={handleLogOut}
                      >
                        Logout
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditModal
        show={showModal}
        hide={() => {
          setShowModal(false);
        }}
        onClose={handleOnClose}
      />
    </header>
  );
};
export default Header;
