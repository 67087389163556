import React from "react";
import loginbg from "../../../src/assets/images/login12.jpg";
// import logo from "../../../src/assets/images/logo.png";
import '../../index.css';
import './style.scss';

const LoginContainer = (props) => {
    return (
        <div className="Login-container">
            <div className="container-fluid bg-lightgrey">
                <div className="row kd">
                    {/* <div className="col-md-3 px-0 login-banner"  style={{ width: '100%', height: '100vh' }}>
                        <img
                            style={{ width: '100%', height: '100vh' }}
                            src={loginbg}
                            alt="NachDark"
                        />
                    </div> */}
                    <div className="col-md-12 mob-container">
                        <div className="LoginForm-container" style={{ width: '100%', height: '100vh' }}>
                            <div className="LoginForm">
                                {/* <div className="logo-img">
                                    <img src={logo} className="img-fluid logo" alt="" />
                                </div> */}
                                <div className="login-header">
                                    {props.children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default LoginContainer;
