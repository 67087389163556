import React, { Fragment, useState, useRef } from "react";
import Button from '../../components/UI/Button/Button';
import LoginContainer from "../../components/LoginContainer/loginContainer";
import notify from "../../shared/notify";
import './style.scss'
import { useStores } from "../../stores";
import Loader from "../../helper/Loader/Loader";

const Login = () => {
  const { authStore } = useStores();
  const textFocus = useRef()
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false)
  const [showpassword, setShowpassword] = useState("password")

  const handleLogin = async (e) => {
    setIsLoading(true)
    e.preventDefault();
    if (userName === "") {
      notify("Email cannot be empty", "danger");
      setIsLoading(false)
      return;
    }
    if (password === "") {
      notify("Password cannot be empty", "danger");
      setIsLoading(false)
      return;
    }
    let payload = {
      email: userName,
      password: password,
    };
    await authStore.login(payload, (response) => {
      if (response?.data?.meta?.status === "success") {
        sessionStorage.setItem('isAuth', true)
        sessionStorage.setItem("token", response?.data?.data?.token);
        sessionStorage.setItem("id", response?.data?.data?.id)
        sessionStorage.setItem("name", response?.data?.data?.name)
        sessionStorage.setItem("role", response?.data?.data?.role)
        sessionStorage.setItem("roleId", response?.data?.data?.roleId)
        sessionStorage.setItem("date", response?.data?.data?.date)
        window.location.href = `/`
        setIsLoading(false)
      }
      else {
        notify(response?.data?.meta?.message, "danger")
        setIsLoading(false)

      }
    }, (error) => {
      notify(error?.response?.data?.meta?.message, "danger")
      setIsLoading(false)

    })
  }

  const togglePassword = () => {
    if (showpassword === "password") {
      setShowpassword("text")
      return;
    }
    setShowpassword("password")
  }

  return (
    <Fragment>
      <LoginContainer>
        <div className="login-box">
          <div className="login-heading">
            <h2>Welcome to the Portal</h2>
          </div>
          <form
            autoComplete="off"
            onSubmit={(e) => { handleLogin(e) }}
          >
            {isLoading && <Loader />}
            <div className="form-input">
              <label className="loan-lable">Email</label>
              <input
                className="form-control"
                type="text"
                placeholder="Email"
                required=""
                style={{textTransform:"none"}}
                value={userName}
                autoComplete="off"
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
                ref={textFocus}
              />
            </div>
            <div className="form-input mt-4">
              <label className="loan-lable">Password</label>
              <div className="position-relative">
                <input
                  className="form-control"
                  type={showpassword}
                  placeholder="Password"
                  autoComplete="off"
                  required=""
                  style={{textTransform:"none"}}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                <div className="password-hideshow">
                  <div type="button" onClick={togglePassword}>
                    {showpassword === "password" ?
                      <img src={require('../../assets/images/eye-slash-solid.png')} alt="" />
                      :
                      <img src={require('../../assets/images/eye-solid.png')} alt="" />
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5 text-center">
              <Button
                type="submit"
                buttonStyle="btn--primary--solid"
                buttonSize="btn-large"
              >
                Login
              </Button>

            </div>
          </form>
        </div>
      </LoginContainer>
    </Fragment>
  )
}
export default Login;
