import React from "react";

const ErrorPage = () => {
  return (
    <div className="leaddetails-container">
      <div className="RightArea">
        <div className="dashboard-area">
          <div className="container-fluid dash-container">
            <div className="needs-validation">
              <h1>404 - Not Found</h1>
              <p>Sorry, the page you are looking for does not exist.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
