import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./modal.scss";

function Confirmation(props) {
  return (
    <Modal {...props} centered className="custom-confirmationModal">
      <Modal.Body >
        <div className="moodal">
          <div className="conform-text">
            <h5 className="text-centerr">Are you sure?</h5>
            <p>you want to delete ?</p>
          </div>
          <div className="confirmation-modal">   <button
             className="btn-cancel"
              style={{ marginRight: 15 }}
              onClick={() => props.hide()}
            >
              Cancel
            </button>

            <button
               className="btn-submit"
              style={{ marginRight: 15 }}
              onClick={() => props.handleDeleteConfirmation()}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default Confirmation;
