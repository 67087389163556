import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import deletebtn from "../../assets/images/trash (1).png";
import editbtn from "../../assets/images/edit (3).png";
import "./style.scss";
import DeleteModal from "../../components/Modal/PartyDeleteModal";
import HallEditModal from "../../components/Modal/PartyEditModal";
import notify from "../../shared/notify";
import { useStores } from "../../stores";
import Loader from "../../helper/Loader/Loader";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const limit = [
  {
    option: 2,
    value: 2,
  },
  {
    option: 10,
    value: 10,
  },
  {
    option: 100,
    value: 100,
  },
  {
    option: 500,
    value: 500,
  },
  {
    option: 1000,
    value: 1000,
  },
];

function Party() {
  const [partyies, setPartyies] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { authStore } = useStores();
  const [selectedVoucher, setSelectedVoucher] = useState();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [partyItem, setPartyItem] = useState();
  const [addedName, setAddedName] = useState("");
  const [filterName, setFilterName] = useState("");
  const [pageLimit, setPageLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [partyData, setPartyData] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const role = sessionStorage.getItem("role");
  const userId = sessionStorage.getItem("id")

  useEffect(() => {
    setIsAdmin(role === "Admin");
    handleparty();
  }, []);

  const handleparty = async () => {
    setIsLoading(true);
    let payload = {
      limit: 10,
      filters: {},
      pageNumber: 1,
    };
    callApi(payload);
  };

  const handleAddCustomOption = async () => {
    setIsLoading(true);
    let payload = {
      name: addedName,
    };
    await authStore.addParty(
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          notify(response?.data?.meta?.message);
          setIsLoading(false);
          setAddedName("");
          handleparty();
        } else {
          notify(response?.data?.meta?.message, "danger");
          setAddedName("");
          setIsLoading(false);
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setAddedName("");
        setIsLoading(false);
      }
    );
  };

  const handleEdit = (e, item) => {
    e.preventDefault();
    setSelectedVoucher(item);
    setShowModal(true);
  };

  const handleConfirmation = async () => {
    let payload = {
      userId: userId,
    }
    await authStore.deletePartyName(
      partyItem.id, payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          notify(response?.data?.meta?.message);
          setShowConfirmationModal(false);
          handleparty();
        } else {
          notify(response?.data?.meta?.message, "danger");
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
      }
    );
  };

  const handleDeleteEntry = (e, item) => {
    e.preventDefault();
    setPartyItem(item);
    setShowConfirmationModal(true);
  };

  const handleFilter = async () => {
    setIsLoading(true);
    let payload = {
      limit: pageLimit,
      filters: {
        name: filterName,
      },
      pageNumber: page,
    };
    callApi(payload);
  };

  const handleReset = async () => {
    setFilterName("");
    setPage(1);
    setPageLimit(10);
    await handleparty();
  };

  const handlePageSubmit = async (index) => {
    setIsLoading(true);
    setPage(index.selected + 1);
    let payload = {
      limit: pageLimit,
      filters: {
        name: filterName,
      },
      pageNumber: index.selected + 1,
    };
    callApi(payload);
  };

  const limitChangeHandler = async (index) => {
    setIsLoading(true);
    setPageLimit(index);
    let payload = {
      limit: index,
      filters: {
        name: filterName,
      },
      pageNumber: page,
    };
    callApi(payload);
  };

  const callApi = async (payload) => {
    await authStore.parties(
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          setPartyData(response?.data?.data);
          setPartyies(response?.data?.data?.filteredData?.results);
          setIsLoading(false);
        } else {
          notify(response?.meta?.message, "danger");
          setIsLoading(false);
        }
      },
      (error) => {
        notify(error?.response?.meta?.message, "danger");
        setIsLoading(false);
      }
    );
  };

  return (
    <>
      <div className="leaddetails-container">
        <div className="RightArea">
          <div className="dashboard-area">
            <div className="container-fluid dash-container">
              <div className="needs-validation">
                <div className="row">
                  <div className="col-md-12">
                    <div className="hall-header">
                      <h2 className="sub-heading">Parties</h2>
                    </div>
                  </div>
                  <div className="receipt-form-box">
                    <div className="row d-flex align-items-center">
                      <div className="col-md-3">
                        <label className="input-lable">Add Name</label>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            id="customName"
                            placeholder="Party Name"
                            value={addedName?.toUpperCase()}
                            onChange={(e) => {
                              let data = e.target.value.toUpperCase();
                              setAddedName(data)
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <button
                          className="add-button"
                          onClick={handleAddCustomOption}
                        >
                          Add Name
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="needs-validation">
                <div className="row">
                  <div className="col-md-12">
                    <div className="hall-header">
                      <h2 className="sub-heading">Entries</h2>
                    </div>
                  </div>
                  <div className="col-md-12 filter-container">
                    <div>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Party Name"
                        value={filterName?.toUpperCase()}
                        onChange={(e) => setFilterName(e.target.value)}
                      />
                    </div>

                    <div className="btn-container">
                      <button
                        className="submit-button"
                        type="submit"
                        onClick={handleFilter}
                      >
                        Filter
                      </button>
                      <button
                        className="submit-button"
                        type="submit"
                        onClick={handleReset}
                      >
                        Reset
                      </button>
                      {partyies.length > 0 &&
                        <button className="submit-button">
                          <ExcelFile element={<span style={{ cursor: "pointer" }}  > Export  </span>} >
                            <ExcelSheet
                              data={partyies?.map((item, i) => ({
                                ...item,
                                index: i + 1,
                              }))}
                              name="Sheet"
                            >
                              <ExcelColumn label="Sr.No" value="index" />
                              <ExcelColumn label="Party Name" value={(item) => item?.name.toUpperCase()} />
                            </ExcelSheet>
                          </ExcelFile>
                        </button>}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div>
                      <label className="d-flex align-items-center p-0 col-md-2">
                        {page === 1 ? (
                          <select
                            value={pageLimit}
                            className="form-control selectbox-container mr-2"
                            onChange={(e) => limitChangeHandler(e.target.value)}
                          >
                            {limit.length > 0
                              ? limit.map((item, index) => (
                                <option key={index} value={item.option}>
                                  {item.value}
                                </option>
                              ))
                              : null}
                          </select>
                        ) : (
                          <p className="form-control selectbox-container mx-2 my-0">
                            {pageLimit}
                          </p>
                        )}
                        <span> entries</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12 listing-table">
                    <div className="data-table  py-4 table-responsive">
                      <table className="details-box table-bordered  ">
                        <thead className="thead-box">
                          <tr className="thead">
                            <th className="action">Sr.No</th>
                            <th className="action">Party Name</th>
                            {role !== "View" && (
                              <th className="action">Action</th>
                            )}
                          </tr>
                        </thead>
                        {partyies.length > 0 ? (
                          <tbody>
                            {partyies.map((item, i) => {
                              return (
                                <>
                                  <tr key={i}>
                                    <td className="table-td">{i + 1}</td>
                                    <td className="table-td">{item.name}</td>
                                    {role !== "View" && (
                                      <td className="table-td">
                                        <button className="disablebtn">
                                          {isAdmin && role !== "View" && (
                                            <img
                                              className="deletebutton"
                                              src={deletebtn}
                                              alt="..."
                                              onClick={(e) =>
                                                handleDeleteEntry(e, item)
                                              }
                                            />
                                          )}
                                          {role !== "View" && (
                                            <img
                                              className="deletebutton"
                                              src={editbtn}
                                              alt="..."
                                              onClick={(e) =>
                                                handleEdit(e, item)
                                              }
                                            />
                                          )}
                                        </button>
                                      </td>
                                    )}
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td className="text-center p-3" colSpan={3}>
                                No Records found...
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="d-flex align-items-center justify-content-between my-4">
                      <div>
                        <p>
                          showing {partyData.filteredCount} out of{" "}
                          {partyData.totalRecords}
                        </p>
                      </div>
                      <div>
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={partyData.totalPages}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageSubmit}
                          containerClassName={
                            "pagination justify-content-center"
                          }
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HallEditModal
        show={showModal}
        hide={() => {
          setShowModal(false);
        }}
        key={selectedVoucher?.name}
        data={selectedVoucher}
        onSubmit={handleparty}
      />
      <DeleteModal
        show={showConfirmationModal}
        hide={() => {
          setShowConfirmationModal(false);
        }}
        handleDeleteConfirmation={() => {
          handleConfirmation();
        }}
      />
      {isLoading && <Loader />}
    </>
  );
}

export default Party;
