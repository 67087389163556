import React from "react";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import '../../index.css'

const SidebarHeader = (props) => {
    return (
        <div className="bg-lightgrey">
            <Header /> 
            <Sidebar />
        </div>
    );
}

export default SidebarHeader;