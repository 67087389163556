import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./modal.scss";
import crossbtn from "../../assets/images/delete-button.png";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import Loader from "../../helper/Loader/Loader";


const EditModal = (props) => {
  const { authStore } = useStores();
  const [partyName, setPartyName] = useState(props?.data?.name);
  const [isLoading, setIsLoading] = useState(false);


  const handleSave = async () => {
    setIsLoading(true)
    let payload = {
      name: partyName,
    };
    props.hide();

    await authStore.editPary(
      props.data.id,
      payload,
      (response) => {
        if (response?.data?.meta?.status === "success") {
          notify(response?.data?.meta?.message, "success");
          setIsLoading(false)
          props.onSubmit();
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false)
        }
      },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false)
      }
    );
  };

  return (
    <Modal {...props} centered className="sucess-content">
      <Modal.Body className="sucess-modal">
        <div className="">
          {isLoading && <Loader />}
          <h4 className="modal-heading">Edit Party</h4>
          <div className="moodal">
            <div
              className="crossbtn-container"
              onClick={() => props.hide()}
              style={{ textAlign: "right" }}
            >
              <img
                className="crossbutton"
                src={crossbtn}
                alt="..."
                style={{
                  width: "28px",
                  position: "absolute",
                  right: "10px",
                  top: "17px",
                }}
              />
            </div>
            <div className="addName-model">
              <label className="input-lable">Party Name</label>
              <br />
              <input
                class="form-control"
                type="text"
                placeholder="Party Name"
                value={partyName}
                onChange={(e) => {
                  let data = e.target.value.toUpperCase();
                  setPartyName(data)
                }}
              />
            </div>
          </div>
          <div className="text-right mt-3">
            <button className="submit-button text-right" onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditModal;
